import { Fragment, ReactElement } from "react";
import { IconButton, Typography } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';
import { HtmlTooltip } from "../../utils/StyledComponents";

// Defining data types  
type HelpToolTipContentType = {
    title: string,
    body: JSX.Element
}

type HelpToolTipPropsType = {
    content: HelpToolTipContentType,
    handleShowToolClose: any,
    openToolTip: boolean,
    handleShowToolOpen: any
}

export function HelpToolTip({ content, handleShowToolClose, openToolTip, handleShowToolOpen }: HelpToolTipPropsType) {
    return (
        <HtmlTooltip
            PopperProps={{
                disablePortal: true,
            }}
            onClose={handleShowToolClose}
            open={openToolTip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
                <Fragment>
                    <Typography color="inherit" variant="subtitle2" mb={1}>
                        <strong>{content.title}</strong>
                    </Typography>
                    <Typography color="inherit" variant="caption" mb={1}>
                        {content.body}
                    </Typography>
                </Fragment>
            }
        >
            <IconButton onClick={handleShowToolOpen}>
                <HelpIcon fontSize='small' color='primary' sx={{ ml: 1 }} />
            </IconButton>
        </HtmlTooltip>
    );
}