import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import FormulaireConsignesTelesurveillance from './pages/forms/instructions/FormulaireConsignesTelesurveillance';
import { Home } from './pages/home/Home';

const font = "'Barlow', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: '#006ea2',
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: font,
    button: {
      textTransform: "none"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Home />
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
