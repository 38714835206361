import { ClickAwayListener } from '@mui/material';
import TitleItem, { TitleItemProps } from './TitleItem';
import './sections-titles.css'

type SectionsTitlesProps = {
    data: Array<TitleItemProps>,
    showMenu: boolean, 
    setShowMenu: any
}

function SectionsTitles(props: SectionsTitlesProps) {
    const element = props.showMenu ? (
        <ClickAwayListener onClickAway={() => {props.setShowMenu(false)}}>
            <div className={`${props.showMenu ? "main-title-container show" : "main-title-container hide"}`}>
                {props.data.map((d, i) => {
                    return (
                        <TitleItem key={i} index={d.index} title={d.title} isActive={d.isActive} isDisabled={d.isDisabled} />
                    );
                })}
            </div>
        </ClickAwayListener>
    ) :
        (
            <div className={`${props.showMenu ? "main-title-container show" : "main-title-container hide"}`}>
                {props.data.map((d, i) => {
                    return (
                        <TitleItem key={i} index={d.index} title={d.title} isActive={d.isActive} isDisabled={d.isDisabled} />
                    );
                })}
            </div>
        )
    return (element);
}

export default SectionsTitles;