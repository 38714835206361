import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Modal, Typography } from "@mui/material";
import "./modals.css"
import VerifiedIcon from '@mui/icons-material/Verified';

type RedirectModalProps = {
    isOpen: boolean,
    handleClose: Function,
    handleOk: Function
}

export default function RedirectModal(props: RedirectModalProps) {

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            lg: "30%",
            md: "50%", 
            xs: "80%"
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        fontFamily: `'Barlow', sans-serif`
    };

    return (
        <Modal
            open={props.isOpen}
            onClose={() => { props.handleClose() }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                {/* En-tete du modal */}
                <div className='validate-modal-header'>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mr: 1}}>
                        Formulaire envoyé! 
                    </Typography>

                    <VerifiedIcon color='success'/>
                    {/* <button className={`close-button`} onClick={() => { props.handleClose() }}><CloseRoundedIcon /></button> */}
                </div>

                {/* Corps du modal */}
                <div className="modal-body">
                    <Typography id="modal-modal-title" variant="body2" component="h2" sx={{ marginBlockEnd: 3, textAlign: 'justify', textJustify: 'inter-word' }}>
                        Votre demande a bien été envoyée à OPTI SECURITE et sera très prochainement traitée.
                        Vous allez recevoir un mail contenant un récapitulatif détaillé de votre demande. 
                    </Typography>
                </div>

                {/* Bouton de validation */}
                <div className="validate-modal-button-container">
                    <Button
                        className={`forward-button`}
                        variant="contained"
                        onClick={() => { props.handleClose() }}>
                        Retourner à la page d'accueil
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}