import { Grid, IconButton, TextField } from '@mui/material';
import '../form-container.css'
import ContactTable, { TableData } from '../../tables/ContactTable';
import { AddCircle } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import AddContactModal from './modals/AddContactModal';
import ValidateChoiceModalModal from './modals/ValidateChoiceModal';
import FormDialog from './modals/ImportFormDialog';
import FormImportDialog from './modals/ImportFormDialog';
import axios from 'axios';
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone';

type FirstFormPropsTypes = {
    register: Function,
    errors: any,
    tableData: Array<TableData>,
    tableDataError: boolean,
    setTableDataError: Function,
    setTableData: Function,
    setImportDialogIsOpen: Function,
    title: string
}

const FirstFormContainer = ({
    register,
    errors,
    tableData,
    setTableData,
    title,
    tableDataError,
    setTableDataError,
    setImportDialogIsOpen
}: FirstFormPropsTypes) => {
    // constants
    const defaultFormObject = { name: "", firstName: "", tel: "", email: "", pwd: "", c: false };
    const tableHeader = ["Nom et prénom(s)", "Téléphone", "E-mail", "C", "actions"];
    const [open, setOpen] = React.useState(false);

    // states 
    const [contactModalStatus, setContactModalStatus] = useState({
        open: false,
        isModify: false, // true when the modal is opened in modify mode rather than add mode
        index: -1,
        selectedContact: defaultFormObject
    });
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        index: -1,
        name: ""
    });

    // Handlers 
    const handleOpenContactModal = (contactIndex: number) => {
        setContactModalStatus({
            open: true,
            isModify: contactIndex != -1,
            index: contactIndex,
            selectedContact: contactIndex != -1 ? tableData[contactIndex] : defaultFormObject
        });
    }
    const handleMoveContactTableElement = (contactIndex: number, operation: string) => {
        if (operation === "UP") {
            let updatedArray = [...tableData];

            // Swap the elements
            let temp = updatedArray[contactIndex];
            updatedArray[contactIndex] = updatedArray[contactIndex - 1];
            updatedArray[contactIndex - 1] = temp;
            setTableData(updatedArray);
        } else {
            let updatedArray = [...tableData];

            // Swap the elements
            let temp = updatedArray[contactIndex];
            updatedArray[contactIndex] = updatedArray[contactIndex + 1];
            updatedArray[contactIndex + 1] = temp;
            setTableData(updatedArray);
        }
    }
    const handleCloseContactModal = () => {
        setContactModalStatus({
            open: false,
            isModify: false,
            index: -1,
            selectedContact: defaultFormObject
        });
    };
    const handleOpenDeleteModal = (contactIndex: number) => {
        setDeleteModalState({
            open: true,
            index: contactIndex,
            name: `${tableData[contactIndex].name} ${tableData[contactIndex].firstName}`
        });
    }
    const handleValidateDelete = () => {
        let filteredData = tableData.filter((_, i) => i !== deleteModalState.index)
        setTableData(filteredData);
        setDeleteModalState({
            open: false,
            index: -1,
            name: ""
        });
        if (filteredData.length === 0) {
            setTableDataError(true);
        }
    }
    const handleCloseDeleteModal = () => {
        setDeleteModalState({
            open: false,
            index: -1,
            name: ""
        });
    }
    const handleContactModify = (editedItem: TableData, index: number) => {
        const updatedData = tableData.map((data, i) =>
            i == index ? editedItem : data
        )
        setTableData(updatedData);
        setContactModalStatus({
            open: false,
            isModify: false,
            index: -1,
            selectedContact: defaultFormObject
        });
    }
    const handleContactAdd = (data: TableData) => {
        setTableData([...tableData, data]);
        setTableDataError(false)
        handleCloseContactModal()
    }

    return (
        <div className="form-card">

            {/* Header */}
            <div className="heading space-between">
                {title}
                <IconButton aria-label="importer des données" onClick={() => setImportDialogIsOpen()}>
                    <DownloadForOfflineTwoToneIcon />
                </IconButton>
            </div>

            {/* Form */}
            <div className="sub-heading">
                Informations personnelles
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Nom*"
                        id="userName"
                        defaultValue={""}
                        {...register('userName')}
                        error={errors.userName ? true : false}
                        helperText={errors.userName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Prénom*"
                        defaultValue={""}
                        id="userFirstName"
                        {...register('userFirstName')}
                        error={errors.userFirstName ? true : false}
                        helperText={errors.userFirstName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Email*"
                        id="userEmail"
                        defaultValue={""}
                        {...register('userEmail')}
                        error={errors.userEmail ? true : false}
                        helperText={errors.userEmail?.message}
                        variant="outlined" />
                </Grid>
            </Grid>

            <div className="sub-heading">
                Coordonnées du site
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom du site*"
                        id="siteName"
                        defaultValue={""}
                        {...register('siteName')}
                        error={errors.siteName ? true : false}
                        helperText={errors.siteName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Rue*"
                        id="address"
                        defaultValue={""}
                        {...register('address')}
                        error={errors.address ? true : false}
                        helperText={errors.address?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField
                        fullWidth
                        label="CP*"
                        id="PC"
                        defaultValue={""}
                        {...register('postalCode')}
                        error={errors.postalCode ? true : false}
                        helperText={errors.postalCode?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Ville*"
                        id="city"
                        defaultValue={""}
                        {...register('city')}
                        error={errors.city ? true : false}
                        helperText={errors.city?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="N° de Téléphone"
                        id="siteTel"
                        defaultValue={""}
                        {...register('sitePhoneNumber')}
                        error={errors.sitePhoneNumber ? true : false}
                        helperText={errors.sitePhoneNumber?.message}
                        variant="outlined"
                    />
                </Grid>
            </Grid>

            <div className={`${tableDataError ? "flex-row-error" : "flex-row"}`}>
                <span className={`sub-heading ${tableDataError ? "error" : ""}`}>Correspondants d'alertes</span>

                {tableData.length <= 0 &&
                    <div className={`message-container ${tableDataError ? "error" : ""}`}>
                        Aucun correspondant n'a été ajouté.
                    </div>
                }

                <IconButton aria-label="delete" disabled={tableData.length >= 5} onClick={() => { handleOpenContactModal(-1) }}>
                    <AddCircle />
                </IconButton>
            </div>

            {tableData.length > 0 &&
                <>
                    <ContactTable
                        data={tableData}
                        handleOpenDeleteModal={handleOpenDeleteModal}
                        handleOpenContactModal={handleOpenContactModal}
                        header={tableHeader}
                        handleMoveContactTableElement={handleMoveContactTableElement}
                    />
                    <TextField
                        fullWidth
                        label="Phrase sous contrainte"
                        id="constraintSentence"
                        defaultValue={""}
                        {...register('constraintSentence')}
                        error={errors.constraintSentence ? true : false}
                        helperText={errors.constraintSentence?.message}
                        variant="outlined"
                        sx={{ marginBlockStart: 2, fontSize: 4, fontFamily: `'Barlow', sans-serif` }} />
                </>
            }

            {/* Add contact modal */}
            <AddContactModal
                contactModalStatus={contactModalStatus}
                handleCloseContactModal={handleCloseContactModal}
                handleOpenContactModal={handleOpenContactModal}
                handleContactAdd={handleContactAdd}
                handleContactModify={handleContactModify}
            />

            {/* Delete contact modal */}
            <ValidateChoiceModalModal
                isLoading={false}
                isOpen={deleteModalState.open}
                handleClose={handleCloseDeleteModal}
                handleOk={handleValidateDelete}
                bodyText={<>Êtes-vous sûr de vouloir supprimer le correspondant :  <strong>{deleteModalState.name}</strong> ?</>}
                title={<>Suppression d'un correspondant</>}
            />
        </div >
    );
}

export default FirstFormContainer;