import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, IconButton, Tooltip } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export function createData(
  name: string,
  firstName: string,
  tel: string,
  email: string,
  pwd: string,
  c: boolean,
) {
  return { name, firstName, tel, email, pwd, c };
};

export type TableData = {
  name: string,
  firstName: string,
  tel: string,
  email: string,
  pwd: string,
  c: boolean,
}

type ContactTableProps = {
  data: Array<TableData>,
  header: Array<String>,
  handleOpenDeleteModal: Function,
  handleOpenContactModal: Function,
  handleMoveContactTableElement: Function
}

export default function ContactTable(props: ContactTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.header.map((title, index) => (
              <TableCell key={title + '-' + index} align={index == 0 ? "left" : "right"}> {title} </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <TableRow
              key={row.name + '-' + index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name} {row.firstName}
              </TableCell>
              <TableCell align="right">{row.tel}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
              <TableCell align="right">{row.c ? "Oui" : "Non"}</TableCell>
              <TableCell align="right">
                <div>
                  <Tooltip title="Modifier l'élément">
                    <>
                      <IconButton onClick={() => { props.handleOpenContactModal(index) }} aria-label="Modifier" color="primary" size='small'>
                        <EditNoteIcon fontSize="small" />
                      </IconButton>
                    </>
                  </Tooltip>
                  <Tooltip title="Supprimer l'élément">
                    <>
                      <IconButton aria-label="Supprimer" onClick={() => { props.handleOpenDeleteModal(index) }} color="error" size='small'>
                        <HighlightOffIcon fontSize="small" />
                      </IconButton>
                    </>
                  </Tooltip>
                  <Tooltip title="Faire monter">
                    <>
                      <IconButton aria-label="Supprimer" disabled={index == 0} onClick={() => { props.handleMoveContactTableElement(index, "UP") }} size='small'>
                        <ArrowUpwardRoundedIcon fontSize="small" />
                      </IconButton>
                    </>
                  </Tooltip>
                  <Tooltip title="Faire déscendre">
                    <>
                      <IconButton aria-label="Supprimer" disabled={index == props.data.length - 1} onClick={() => { props.handleMoveContactTableElement(index, "DOWN") }} size='small'>
                        <ArrowDownwardRoundedIcon fontSize="small" />
                      </IconButton>
                    </>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}