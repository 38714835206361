import { FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";

type CheckBoxPropType = {
    title: string,
    data: Array<string>,
    defaultValue: string,
    onChange: Function,
    property: number
}

export function CustomRadioGroup({ title, data, defaultValue, onChange, property }: CheckBoxPropType) {
    return (
        <>
            <FormHelperText id="demo-row-radio-buttons-group-label" >{title}</FormHelperText>
            <FormControl sx={{ p: 1 }}>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    defaultValue={defaultValue}
                    value={defaultValue}
                    onChange={(e)=>{onChange(e, property)}}
                >
                    {data.map((d, index) => (<FormControlLabel key={`${title} - ${index}`} value={d} control={<Radio size="small" />} label={d} />))}
                </RadioGroup>
            </FormControl>
        </>
    )
}