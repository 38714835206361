import { useState } from 'react';
import './history.css'
import HistoryIcon from '@mui/icons-material/History';
import NavBar from '../../components/nav_bar/NavBar';
import HistoryContainer from '../../components/history/HistoryContainer';

export const History = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);

    return (
        <div className="main-container">
            <NavBar setShowMenu={setShowMenu} showToggleButton={true} />
            <div className="main-component-container">
                <HistoryContainer />
            </div>
        </div>
    );
};
