import { ClickAwayListener, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, TextareaAutosize, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import '../form-container.css'
import { ChangeEvent, useEffect, useState } from 'react';
import { interventionsContent, leveeDouteContent, supportContent } from '../../../utils/HelpToolTipContents';
import { HelpToolTip } from '../../utils/HelpToolTips';
import { SelectComponent } from '../../utils/SelectComponent';
import { CustomRadioGroup } from '../../utils/RadioGroup';


export type SupportObjectType = {
    transmissionPrincipale: string,
    frequencePeriodique1: string,
    transmissonSecondaire: string,
    frequencePeriodique2: string
}

export type CheckBoxesType = {
    optionSouscrite: boolean,
    avecMoyen: boolean,
    leveeDouteAudio: boolean,
    leveeDouteVideo: boolean
}

type SecondFormPropsTypes = {
    title: string,
    supports: SupportObjectType,
    checkBoxes: CheckBoxesType,
    setSupports: Function,
    setCheckBoxes: Function,
    register: Function,
    trigger: Function,
    errors: any,
}

export const SecondFormContainer = ({ title, supports, setSupports, checkBoxes, setCheckBoxes, register, trigger, errors }: SecondFormPropsTypes) => {
    // States 
    const [openToolTip, setOpenToolTip] = useState(-1);

    // Handlers
    const handleShowToolClose = (val: number) => {
        if (openToolTip === val) {
            setOpenToolTip(-1);
        }
    };
    const handleShowToolOpen = (val: number) => {
        setOpenToolTip(val);
    };
    const handleSelectChange = (e: SelectChangeEvent, property: number) => {
        const changedSupports = supports;
        switch (property) {
            case 0:
                changedSupports.transmissionPrincipale = e.target.value;
                break;
            case 1:
                changedSupports.frequencePeriodique1 = e.target.value;
                break;
            case 2:
                changedSupports.transmissonSecondaire = e.target.value;
                break;
            case 3:
                changedSupports.frequencePeriodique2 = e.target.value;
                break;
        }
        setSupports({ ...changedSupports });
    };
    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>, property: number) => {
        const changedChexBoxes = checkBoxes;
        switch (property) {
            case 0:
                trigger();
                changedChexBoxes.optionSouscrite = e.target.value == 'Oui' ? true : false;
                break;
            case 1:
                changedChexBoxes.avecMoyen = e.target.value == 'Oui' ? true : false;
                break;
            case 2:
                changedChexBoxes.leveeDouteAudio = e.target.value == 'Oui' ? true : false;
                break;
            case 3:
                changedChexBoxes.leveeDouteVideo = e.target.value == 'Oui' ? true : false;
                break;
        }
        setCheckBoxes({ ...changedChexBoxes });
    };

    // useEffects 
    useEffect(() => {
        // scroll back to the top of the card
        const body = document.querySelector('#scrolled-card');
        body?.scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    return (
        <div className="form-card" id='scrolled-card'>

            {/* Header */}
            <div className="heading">
                {title}
            </div>

            <div className="sub-heading">
                Supports de transmission et tests de bon fonctionnement *
                <ClickAwayListener onClickAway={() => handleShowToolClose(1)}>
                    <div>
                        <HelpToolTip
                            content={supportContent}
                            handleShowToolClose={() => handleShowToolClose(1)}
                            handleShowToolOpen={() => handleShowToolOpen(1)}
                            openToolTip={openToolTip === 1}
                        />
                    </div>
                </ClickAwayListener>
            </div>

            <Grid container spacing={2} mt={1} mb={5}>
                <SelectComponent
                    data={["ADSL/SDSL/Fibre", "3G/4G/5G"]}
                    title='Transmission principale'
                    value={supports.transmissionPrincipale}
                    onChange={handleSelectChange}
                    property={0}
                />

                <SelectComponent
                    data={["24 heures", "10 minutes", "3 minutes"]}
                    title='Fréquence du test périodique'
                    value={supports.frequencePeriodique1}
                    onChange={handleSelectChange}
                    property={1}
                />

                <SelectComponent
                    data={["Aucun", "ADSL/SDSL/Fibre", "3G/4G/5G"]}
                    title='Transmission secondaire'
                    value={supports.transmissonSecondaire}
                    onChange={handleSelectChange}
                    property={2}
                />

                {supports.transmissonSecondaire != "Aucun" &&
                    (
                        <SelectComponent
                            data={["24 heures", "10 minutes", "3 minutes"]}
                            title='Fréquence du test périodique'
                            value={supports.frequencePeriodique2}
                            onChange={handleSelectChange}
                            property={3}
                        />
                    )
                }
            </Grid>

            <div className="sub-heading">
                Interventions sur alarme *
                <ClickAwayListener onClickAway={() => handleShowToolClose(2)}>
                    <div>
                        <HelpToolTip
                            content={interventionsContent}
                            handleShowToolClose={() => handleShowToolClose(2)}
                            handleShowToolOpen={() => handleShowToolOpen(2)}
                            openToolTip={openToolTip === 2}
                        />
                    </div>
                </ClickAwayListener>
            </div>
            <Grid
                container
                spacing={2} mt={0}
                mb={5}
            >
                <Grid item xs={6} md={3}>
                    <CustomRadioGroup
                        title='Option souscrite'
                        data={["Oui", "Non"]}
                        defaultValue={checkBoxes.optionSouscrite ? 'Oui' : 'Non'}
                        onChange={handleRadioChange}
                        property={0}
                    />
                </Grid>
                {
                    checkBoxes.optionSouscrite &&
                    (
                        <>
                            <Grid item xs={6} md={3}>
                                <CustomRadioGroup
                                    title='Avec moyens d’accès'
                                    data={["Oui", "Non"]}
                                    defaultValue={checkBoxes.avecMoyen ? 'Oui' : 'Non'}
                                    onChange={handleRadioChange}
                                    property={1}
                                />
                            </Grid>
                            {checkBoxes.avecMoyen && (
                                <Grid item xs={12} md={6} alignItems={'flex-end'}>
                                    <TextField
                                        fullWidth
                                        label="Code du système d’alarme"
                                        id="constraintSentence"
                                        variant="outlined"
                                        {...register('systemCode', { shouldUnregister: true })}
                                        error={errors.systemCode ? true : false}
                                        helperText={errors.systemCode?.message}
                                        sx={{ marginBlockStart: 2.5, fontSize: 4, fontFamily: `'Barlow', sans-serif` }} />
                                </Grid>
                            )}
                            <Grid item xs={12} alignItems={'flex-end'}>
                                <TextField
                                    fullWidth
                                    label="Coordonnées du prestataire"
                                    id="constraintSentence"
                                    defaultValue={""}
                                    variant="outlined"
                                    {...register('contactDetails')}
                                    error={errors.contactDetails ? true : false}
                                    helperText={errors.contactDetails?.message}
                                    sx={{ marginBlockStart: .5, fontSize: 4, fontFamily: `'Barlow', sans-serif` }} />
                            </Grid>
                        </>
                    )
                }
            </Grid>

            <div className="sub-heading">
                Levée de doute audio/vidéo *
                <ClickAwayListener onClickAway={() => handleShowToolClose(3)}>
                    <div>
                        <HelpToolTip
                            content={leveeDouteContent}
                            handleShowToolClose={() => handleShowToolClose(3)}
                            handleShowToolOpen={() => handleShowToolOpen(3)}
                            openToolTip={openToolTip === 3}
                        />
                    </div>
                </ClickAwayListener>
            </div>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={6} md={3}>
                    <CustomRadioGroup
                        title='Levée de doute audio'
                        data={["Oui", "Non"]}
                        defaultValue={checkBoxes.leveeDouteAudio ? 'Oui' : 'Non'}
                        onChange={handleRadioChange}
                        property={2}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <CustomRadioGroup
                        title='Levée de doute vidéo'
                        data={["Oui", "Non"]}
                        defaultValue={checkBoxes.leveeDouteVideo ? 'Oui' : 'Non'}
                        onChange={handleRadioChange}
                        property={3}
                    />
                </Grid>
            </Grid>

        </div >
    );
}

