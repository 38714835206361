import { Button, Grid, Tooltip } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from "dayjs"

type TimeScheduleProps = {
    data: Array<Dayjs | null>,
    titles: Array<string>,
    onChange: Function,
    day: number,
    toolTipsTitles: Array<string>
}

export const TimeSchedule = (props: TimeScheduleProps) => {
    const onKeyDown = (e: any) => {
        e.preventDefault();
    };

    return (
        <Grid container spacing={2}>
            {props.data.map((data, index) => (
                <Tooltip
                    key={`${props.titles[index]}-${index}`}
                    title={props.toolTipsTitles[index]}
                    // placement="top"
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [60, -14],
                                    },
                                },
                            ],
                        },
                    }}>
                    <Grid item xs={6} sm={props.data.length > 2 ? 4 : 6} md={props.data.length > 2 ? 3 : 6}>
                        <TimePicker
                            className="time-picker"
                            label={props.titles[index]}
                            value={dayjs(data)}
                            ampm={false}
                            // minTime={index % 2 == 1 ? props.data[index - 1] : null}
                            slotProps={{
                                textField: {
                                    onKeyDown: onKeyDown
                                }
                            }}
                            onChange={(e) => {
                                props.onChange(e, index, props.day)
                            }}
                        />
                    </Grid>
                </Tooltip>
            ))}
        </Grid >
    )
}