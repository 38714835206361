import { AddCircle } from '@mui/icons-material';
import { Grid, IconButton, Skeleton, TextField } from '@mui/material';
import React from 'react';
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone';
interface Props {
    // : string;
};

export const AnimatedSkeletonFirstForm = ({ }: Props) => {
    return (
        <div className="form-card">

            {/* Header */}
            <div className="heading space-between">
                1. Coordonnées et correspondants
                {/* Import data dialog */}
                <DownloadForOfflineTwoToneIcon />
            </div>

            {/* Form */}
            <div className="sub-heading">
                Informations personnelles *
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12} sm={6}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
            </Grid>

            <div className="sub-heading">
                Coordonnées du site *
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Skeleton
                        variant="rounded"
                        height={55}
                        animation="wave"
                    />
                </Grid>
            </Grid>

            <div className={"flex-row"}>
                <span className={`sub-heading`}>Correspondants d'alertes *</span>
                <IconButton aria-label="delete">
                    <AddCircle />
                </IconButton>
            </div>

            <Skeleton
                variant="rounded"
                height={110}
                animation="wave"
            />
            <Skeleton
                variant="rounded"
                height={55}
                sx={{ marginBlockStart: 2 }}
                animation="wave"
            />

        </div >
    );
};
