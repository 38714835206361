import { useState } from "react";
import "./title-item.css"
import { type } from "os";

export type TitleItemProps = {
    index : number;
    title : string;
    isActive : boolean;
    isDisabled : boolean;
}

function TitleItem ( props : TitleItemProps) {

    const selectedTabIndex = useState(1);

    return (
        <>
            <div className={`item ${props.isActive ? "active-title":""}`}>
                <div className={`${props.isDisabled? "disabled-title-chip" : "title-chip"}`}>{props.index}</div>
                <span className={`${props.isDisabled? "disabled-section-title" : "section-title"}`}>{props.title}</span>
            </div>
        </>
    )
}

export default TitleItem;