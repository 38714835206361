import { Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@mui/material';
import '../form-container.css'
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone';
import { CustomRadioGroup } from '../../utils/RadioGroup';
import { ChangeEvent } from 'react';

type ThirdFormPropsTypes = {
    register: Function,
    errors: any,
    title: string,
    paymentCollectionType: string,
    setPaymentCollectionType: Function
}

const ThirdFormContainer = ({
    register,
    errors,
    title,
    paymentCollectionType, 
    setPaymentCollectionType
}: ThirdFormPropsTypes) => {
    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>, property: number) => {
        setPaymentCollectionType(e.target.value);
    };
    return (
        <div className="form-card">

            {/* Header */}
            <div className="heading space-between">
                {title}
            </div>

            {/* Form */}
            <div className="sub-heading">
                Identification du mandat
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Référence unique du mandat*"
                        id="uniqueMandateRef"
                        defaultValue={""}
                        {...register('uniqueMandateRef')}
                        error={errors.uniqueMandateRef ? true : false}
                        helperText={errors.uniqueMandateRef?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Objet du mandat*"
                        id="mandateObject"
                        defaultValue={""}
                        {...register('mandateObject')}
                        error={errors.mandateObject ? true : false}
                        helperText={errors.mandateObject?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <CustomRadioGroup
                        title='Type d’encaissement'
                        data={["récurrent", "unique"]}
                        onChange={handleRadioChange}
                        defaultValue={paymentCollectionType}
                        property={0}
                    />
                </Grid>
            </Grid>

            <div className="sub-heading">
                Identification du débiteur
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom"
                        id="deptorName"
                        defaultValue={""}
                        {...register('deptorName')}
                        error={errors.deptorName ? true : false}
                        helperText={errors.deptorName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Adresse"
                        id="deptorAddress"
                        defaultValue={""}
                        {...register('deptorAddress')}
                        error={errors.deptorAddress ? true : false}
                        helperText={errors.deptorAddress?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField
                        fullWidth
                        label="CP"
                        id="PC"
                        defaultValue={""}
                        {...register('deptorPC')}
                        error={errors.deptorPC ? true : false}
                        helperText={errors.deptorPC?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        fullWidth
                        label="Ville"
                        id="deptorCity"
                        defaultValue={""}
                        {...register('deptorCity')}
                        error={errors.deptorCity ? true : false}
                        helperText={errors.deptorCity?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="numéro de compte (IBAN)"
                        id="deptorIBAN"
                        defaultValue={""}
                        {...register('deptorIBAN')}
                        error={errors.deptorIBAN ? true : false}
                        helperText={errors.deptorIBAN?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Code BIC"
                        id="deptorBICCode"
                        defaultValue={""}
                        {...register('deptorBICCode')}
                        error={errors.deptorBICCode ? true : false}
                        helperText={errors.deptorBICCode?.message}
                        variant="outlined" />
                </Grid>
            </Grid>
        </div >
    );
}

export default ThirdFormContainer;