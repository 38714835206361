import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, CircularProgress, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { getDayIndex, orderedSelectedDays, thirdFormDays, thirdFormFieldTitles, thirdFormFieldTooltips } from '../../../../utils/FormDataDefaultValues';
import dayjs, { Dayjs } from 'dayjs';
import { TimeSchedule } from '../../../utils/TimeSchedule';
import { json } from 'stream/consumers';
import { ScheduleTableData } from '../ThirdForm_2';

type FormImportDialogProps = {
    open: boolean,
    handleClose: any,
    handleValidate: any,
    selectedDays: string[],
    deactivateDays: string[],
    setSelectedDays: Function,
    moifyStatus: any
}

export default function ModifyScheduleDialog({
    open,
    handleClose,
    handleValidate,
    selectedDays,
    deactivateDays,
    setSelectedDays,
    moifyStatus
}: FormImportDialogProps) {
    // states
    const [defaultDateValue, setDefaultDateValue] = React.useState([dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')]);

    React.useEffect(()=>{
        setDefaultDateValue(moifyStatus.selected.horaires); 
    }, [moifyStatus.selected.horaires])
    // handlers
    const handleAddGroup = () => {
        const nextGroup = (defaultDateValue.length / 2) + 1;
        if (defaultDateValue.length == thirdFormFieldTitles.length) {
            thirdFormFieldTitles.push("MHS G" + nextGroup, "MES G" + nextGroup);
            thirdFormFieldTooltips.push(
                `Groupe ${nextGroup} : Heure de mise hors service des systèmes`,
                `Groupe ${nextGroup} : Heure de mise en service des systèmes`
            );
        }
        const changedDateValues = defaultDateValue;
        changedDateValues.push(dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00'));
        // console.log("my data :", changedDateValues);
        setDefaultDateValue([...changedDateValues]);
    }
    const handleRemoveGroup = () => {
        var changedDateValues = defaultDateValue;
        changedDateValues = changedDateValues.slice(0, -2);
        setDefaultDateValue([...changedDateValues]);
    }
    const handleDateChange = (e: any, property: number) => {
        const changedDateValues = defaultDateValue;
        changedDateValues[property] = e;
        setDefaultDateValue([...changedDateValues]);
    }
    const handleSelectedDaysChange = (event: SelectChangeEvent<typeof selectedDays>, obj: any) => {
        const {
            target: { value },
        } = event;
        setSelectedDays(
            orderedSelectedDays(typeof value === 'string' ? value.split(',') : value)
        );
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{ '& .MuiDialog-paper': { width: 630, maxHeight: 435 } }}
            >
                <DialogTitle>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} mt={0}>
                        <span className='add-schedule-modal-title '>Ajout de planning horaire</span>
                        <Grid item xs={12} md={6}>
                            <FormControl sx={{ width: "100%" }} size='medium'>
                                <InputLabel id="demo-multiple-checkbox-label">Jours d'activité</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={selectedDays}
                                    onChange={handleSelectedDaysChange}
                                    input={<OutlinedInput label="Jours d'activité" />}
                                    renderValue={(selected) => selected.join(', ')}
                                // MenuProps={MenuProps}
                                >
                                    {thirdFormDays.filter((d)=>(!deactivateDays.includes(d))).map((name, index) => (
                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={selectedDays.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {/* {isLoading && <CircularProgress disableShrink size=".8rem" sx={{ ml: 2 }} />} */}
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        Veuillez entrer l'identifiant de référence du formulaire que vous souhaitez importer.
                    </DialogContentText> */}

                    <div className="date-container">
                        <div className="row">
                            <div className="sub-heading-2">
                                Planning :
                            </div>
                            <div className='add-group-btn-container'>
                                <IconButton color="error" disabled={defaultDateValue.length <= 2} size='small' onClick={() => handleRemoveGroup()}>
                                    <RemoveCircleIcon />
                                </IconButton>
                                <IconButton color="primary" size='small' disabled={defaultDateValue.length >= 8} onClick={() => handleAddGroup()}>
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                        </div>
                        {/* Heures d'ouverture*/}
                        <TimeSchedule
                            data={defaultDateValue}
                            titles={thirdFormFieldTitles}
                            onChange={handleDateChange}
                            day={0}
                            toolTipsTitles={thirdFormFieldTooltips} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button onClick={()=>{handleValidate(defaultDateValue)}}>Modifier</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}