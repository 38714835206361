import React, { useState } from 'react';
import { TitleItemProps } from '../../../components/remote_surveillance_instructions_forms/TitleItem';
import NavBar from '../../../components/nav_bar/NavBar';
import SectionsTitles from '../../../components/remote_surveillance_instructions_forms/SectionsTitles';
import ContractFormContainer from '../../../components/remote_surveillance_contract_forms/ContractFormContainer';

export const FormulaireContrat = () => {
    const [tabIndex, setTabIndex] = useState<number>(1);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    // const sectionTitles: Array<string> = ["Identification", "Coordonnées personnelles", "Consignes de sécurité", "Politique de confidentialité"];
    // Forms titles 
    const data: Array<TitleItemProps> = [
        { index: 1, title: 'Contrat de télésurveillance', isActive: tabIndex == 1, isDisabled: false },
        { index: 2, title: 'Conditions particulières', isActive: tabIndex == 2, isDisabled: tabIndex < 2 },
        { index: 3, title: 'Mandat de prélèvement', isActive: tabIndex == 3, isDisabled: tabIndex < 3 }
    ];

    return (
        <div className="main-container">
            <NavBar setShowMenu={setShowMenu} showToggleButton={true} />
            <div className="main-component-container">
                <SectionsTitles data={data} showMenu={showMenu} setShowMenu={setShowMenu} />
                <ContractFormContainer data={data} tabIndex={tabIndex} setTabIndex={setTabIndex}/>
            </div>
        </div>
    );
};
