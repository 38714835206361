import { ClickAwayListener, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import '../form-container.css'
import { useEffect, useState } from 'react';
import { horaireOuvertureContent, infosTreatmentContent } from '../../../utils/HelpToolTipContents';
import { HelpToolTip } from '../../utils/HelpToolTips';

export type SelectObjectType = {
    tempo: string,
    niveauAlerte: string,
    modeCom: string,
    inter: string
}
type FourthFormPropsTypes = {
    title: string,
    titlesChamps: Array<string>,
    titlesSections: Array<string>,
    data: Array<SelectObjectType>,
    setData: Function,
    hasOpenningHours: boolean
}

export const FourthFormContainer = ({ title, data, titlesChamps, titlesSections, setData, hasOpenningHours }: FourthFormPropsTypes) => {
    // States 
    const [openToolTip, setOpenToolTip] = useState(-1);

    // Handlers
    const handleSelectValueChange = (event: SelectChangeEvent, index: number, property: string) => {
        var newSelectData = data;
        switch (property) {

            case titlesChamps[0]:
                newSelectData[index].tempo = event.target.value;
                break;

            case titlesChamps[1]:
                newSelectData[index].niveauAlerte = event.target.value;
                break;

            case titlesChamps[2]:
                newSelectData[index].modeCom = event.target.value;
                break;

            case titlesChamps[3]:
                newSelectData[index].inter = event.target.value;
                break;
        }
        setData([...newSelectData]);
    }
    const handleShowToolClose = (val: number) => {
        if (openToolTip === val) {
            setOpenToolTip(-1);
        }
    };
    const handleShowToolOpen = (val: number) => {
        setOpenToolTip(val);
    };

    // useEffects 
    useEffect(() => {
        // scroll back to the top of the card
        const body = document.querySelector('#scrolled-card');
        body?.scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    return (
        <div className="form-card" id='scrolled-card'>

            {/* Header */}
            <div>
                <div className="heading row-2">
                    {title}
                    <ClickAwayListener onClickAway={() => handleShowToolClose(3)}>
                        <div>
                            <HelpToolTip
                                content={infosTreatmentContent}
                                handleShowToolClose={() => handleShowToolClose(3)}
                                handleShowToolOpen={() => handleShowToolOpen(3)}
                                openToolTip={openToolTip === 3}
                            />
                        </div>
                    </ClickAwayListener>
                </div>
            </div>

            {data.map((data, index) => (((!hasOpenningHours && (index < 4 || index > 6)) || hasOpenningHours) && // we remove or add entries related to time scheduling (hasOpenningHours)
                <div key={`select-container#${index}`} className="date-container">
                    <div className="sub-heading-2">
                        {titlesSections[index].toUpperCase()} :
                    </div>

                    {/* Heures d'ouverture */}
                    <Grid container spacing={2}>
                        <Grid item xs={4} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{titlesChamps[1]}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.niveauAlerte}
                                    label={titlesChamps[1]}
                                    onChange={(e) => { handleSelectValueChange(e, index, titlesChamps[1],) }}
                                // onChange={handleChange}
                                >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={"1"}>1</MenuItem>
                                    <MenuItem value={"2"}>2</MenuItem>
                                    <MenuItem value={"SO"}>SO</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{titlesChamps[0]}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.tempo}
                                    label={titlesChamps[0]}
                                    disabled={data.niveauAlerte == "SO"}
                                    onChange={(e) => { handleSelectValueChange(e, index, titlesChamps[0],) }}
                                >
                                    <MenuItem value={"0"}>0</MenuItem>
                                    <MenuItem value={"5"}>5</MenuItem>
                                    <MenuItem value={"15"}>15</MenuItem>
                                    <MenuItem value={"30"}>30</MenuItem>
                                    <MenuItem value={"60"}>60</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4} md={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{titlesChamps[3]}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.inter}
                                    label={titlesChamps[3]}
                                    disabled={data.niveauAlerte == "SO"}
                                    onChange={(e) => { handleSelectValueChange(e, index, titlesChamps[3],) }}
                                // onChange={handleChange}
                                >
                                    <MenuItem value={"Oui"}>Oui</MenuItem>
                                    <MenuItem value={"Non"}>Non</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>{titlesChamps[2]}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={data.modeCom}
                                    label={titlesChamps[2]}
                                    disabled={data.niveauAlerte == "SO"}
                                    onChange={(e) => { handleSelectValueChange(e, index, titlesChamps[2],) }}
                                // onChange={handleChange}
                                >
                                    <MenuItem value={"Appel téléphonique"}>Appel téléphonique</MenuItem>
                                    <MenuItem value={"E-mail"}>E-mail</MenuItem>
                                    {/* <MenuItem value={"SMS"}>SMS</MenuItem> */}
                                    <MenuItem value={"Application"}>Application</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            ))}
        </div >
    );
}