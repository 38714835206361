import { Checkbox, FormControlLabel, Grid, IconButton, TextField } from '@mui/material';
import '../form-container.css'
import DownloadForOfflineTwoToneIcon from '@mui/icons-material/DownloadForOfflineTwoTone';

type FirstFormPropsTypes = {
    register: Function,
    errors: any,
    setImportDialogIsOpen: Function,
    setIsCommunicationConsentOk: Function,
    title: string
}

const FirstFormContainer = ({
    register,
    errors,
    title,
    setImportDialogIsOpen,
    setIsCommunicationConsentOk
}: FirstFormPropsTypes) => {
    return (
        <div className="form-card">

            {/* Header */}
            <div className="heading space-between">
                {title}
                <IconButton aria-label="importer des données" onClick={() => setImportDialogIsOpen()}>
                    <DownloadForOfflineTwoToneIcon />
                </IconButton>
            </div>

            {/* Form */}
            <div className="sub-heading">
                Coordonnées du site
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom du site*"
                        id="siteName"
                        defaultValue={""}
                        {...register('siteName')}
                        error={errors.siteName ? true : false}
                        helperText={errors.siteName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Rue*"
                        id="siteAddress"
                        defaultValue={""}
                        {...register('siteAddress')}
                        error={errors.siteAddress ? true : false}
                        helperText={errors.siteAddress?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField
                        fullWidth
                        label="CP*"
                        id="PC"
                        defaultValue={""}
                        {...register('sitePostalCode')}
                        error={errors.sitePostalCode ? true : false}
                        helperText={errors.sitePostalCode?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        fullWidth
                        label="Ville*"
                        id="siteCity"
                        defaultValue={""}
                        {...register('siteCity')}
                        error={errors.siteCity ? true : false}
                        helperText={errors.siteCity?.message}
                        variant="outlined" />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="N° de Téléphone"
                        id="siteTel"
                        defaultValue={""}
                        {...register('sitePhoneNumber')}
                        error={errors.phoneNumber ? true : false}
                        helperText={errors.phoneNumber?.message}
                        variant="outlined"
                    />
                </Grid> */}
            </Grid>

            <div className="sub-heading">
                Coordonnées du site de facturation (si différentes) 
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Nom du site"
                        id="siteFacturationName"
                        defaultValue={""}
                        {...register('siteFacturationName')}
                        error={errors.siteFacturationName ? true : false}
                        helperText={errors.siteFacturationName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Rue"
                        id="siteFacturationAddress"
                        defaultValue={""}
                        {...register('siteFacturationAddress')}
                        error={errors.siteFacturationAddress ? true : false}
                        helperText={errors.siteFacturationAddress?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={6} sm={2}>
                    <TextField
                        fullWidth
                        label="CP"
                        id="PC"
                        defaultValue={""}
                        {...register('siteFacturationPostalCode')}
                        error={errors.siteFacturationPostalCode ? true : false}
                        helperText={errors.siteFacturationPostalCode?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={10}>
                    <TextField
                        fullWidth
                        label="Ville"
                        id="siteFacturationCity"
                        defaultValue={""}
                        {...register('siteFacturationCity')}
                        error={errors.siteFacturationCity ? true : false}
                        helperText={errors.siteFacturationCity?.message}
                        variant="outlined" />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="N° de Téléphone"
                        id="siteTel"
                        defaultValue={""}
                        {...register('sitePhoneNumber')}
                        error={errors.phoneNumber ? true : false}
                        helperText={errors.phoneNumber?.message}
                        variant="outlined"
                    />
                </Grid> */}
            </Grid>

            <div className="sub-heading">
                Correspondant référent
            </div>
            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Nom*"
                        id="userName"
                        defaultValue={""}
                        {...register('userName')}
                        error={errors.userName ? true : false}
                        helperText={errors.userName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Prénom*"
                        defaultValue={""}
                        id="userFirstName"
                        {...register('userFirstName')}
                        error={errors.userFirstName ? true : false}
                        helperText={errors.userFirstName?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Email*"
                        id="userEmail"
                        defaultValue={""}
                        {...register('userEmail')}
                        error={errors.userEmail ? true : false}
                        helperText={errors.userEmail?.message}
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="N° de Téléphone*"
                        id="siteTel"
                        defaultValue={""}
                        {...register('sitePhoneNumber')}
                        error={errors.sitePhoneNumber ? true : false}
                        helperText={errors.sitePhoneNumber?.message}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} mt={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked
                                onChange={(event) => {
                                    setIsCommunicationConsentOk(event.target.checked);
                                }} />
                        }
                        label="Le client accepte que ces coordonnées soient utilisées dans le cadre des communications de l’entreprise." />
                </Grid>
            </Grid>
        </div >
    );
}

export default FirstFormContainer;