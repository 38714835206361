import React, { useState } from 'react';
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import { motion, AnimatePresence, delay } from "framer-motion";
import './home.css'
import { ModelContainer } from '../../components/home/ModelContainer';
import FormulaireConsignesTelesurveillance from '../forms/instructions/FormulaireConsignesTelesurveillance';
import { useLocation } from "react-router-dom"
import { duration } from '@mui/material';
import { FormulaireContrat } from '../forms/contrat/FormulaireContratTelesurveillance';
import { History } from '../history/History';

export const Home = () => {
    const [selectedModel, setSelectedModel] = useState(-1); // 0: instructions, 1: contract
    const routeVariants = { // variant permitting right to left page transition
        initial: {
            x: '100vw'
        },
        final: {
            x: '0vw',
            transition: {
                type: "spring",
                mass: 0.4,
                x: { duration: .6}
            },
        }
    }

    // Pages 
    // home page (model selection)
    function ModelSelection() {
        return (
            <motion.div
                className="home-main-container"
            >
                <div className="home-main-component-container">
                    <ModelContainer
                        selectedModel={selectedModel}
                        setSelectedModel={setSelectedModel} />
                </div>
            </motion.div>
        );
    }
    // Instructions form page 
    function InstructionForm() {
        return (
            <motion.div
                variants={routeVariants}
                initial="initial"
                animate="final">
                <FormulaireConsignesTelesurveillance />

            </motion.div>
        );
    }
    function ContractForm() {
        return (
            <motion.div
                variants={routeVariants}
                initial="initial"
                animate="final">
                <FormulaireContrat/>
            </motion.div>
        );
    }

    // animation handling methods 
    function LocationProvider({ children }: any) {
        return <AnimatePresence>{children}</AnimatePresence>;
    }
    function RoutesWithAnimation() {
        const location = useLocation();

        return (
            <Routes location={location} key={location.key}>
                <Route path="/" element={<ModelSelection />} />
                <Route path="/instructions" element={<InstructionForm />} />
                <Route path="/contract" element={<ContractForm />} />
                <Route path="/history" element={<History />} />
            </Routes>
        );
    }

    return (
        <BrowserRouter>
            <AnimatePresence>
                <LocationProvider>
                    <RoutesWithAnimation />
                </LocationProvider>
            </AnimatePresence>
        </BrowserRouter>
    );
};
