import "./form-container.css"
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { contractFirstFormValidationSchema, contractThirdFormValidationSchema, secondFormValidationSchema } from "../../utils/YupConfigurations";
import axios from "axios";
import { AnimatedSkeletonFirstForm } from "../loading_screen/animated_skeleton_first_form";
import { RadioButtonsType, SecondFormContainer, SupportObjectType, subscriptionObjectType } from "./forms/SecondForm";
import { TitleItemProps } from "../remote_surveillance_instructions_forms/TitleItem";
import FirstFormContainer from "./forms/FirstForm";
import NavigationButtons from "../navigation_buttons/NavigationButtons";
import { secondFormDefaultCheckValues, secondFormDefaultSubscriptionPriceValues, secondFormDefaultTransmissionValues } from "../../utils/FormDataDefaultValues";
import ValidateChoiceModalModal from "../remote_surveillance_instructions_forms/forms/modals/ValidateChoiceModal";
import { CircularProgress, FormHelperText } from "@mui/material";
import RedirectModal from "../remote_surveillance_instructions_forms/forms/modals/RedirectModal";
import FormImportDialog from "../remote_surveillance_instructions_forms/forms/modals/ImportFormDialog";
import { useNavigate } from "react-router-dom";
import ThirdFormContainer from "./forms/ThirdForm";


type FirstContainerProps = {
    data: Array<TitleItemProps>,
    tabIndex: number,
    setTabIndex: Function
}

function ContractFormContainer(props: FirstContainerProps) {

    const navigate = useNavigate();
    // States
    const [validationErrorOccured, setValidationErrorOccured] = useState<boolean>(false);
    const [validateModalIsOpen, setValidateModalIsOpen] = useState<boolean>(false);
    const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false);
    const [importModalIsOpen, setImportModalIsOpen] = useState<boolean>(false);
    const [importDataErrorStatus, setImportDataErrorStatus] = useState(0); // 0 : no error, 1 : incorrect id, 2: server error 
    const [isValidateOk, setIsValidateOk] = useState<boolean>(false);
    // First Form 
    const [isCommunicationConsentOk, setIsCommunicationConsentOk] = useState<boolean>(false);
    const [isImportLoading, setIsImportLoading] = useState<boolean>(false);
    // Second Form
    const [checkboxes, setCheckBoxes] = useState<RadioButtonsType>(secondFormDefaultCheckValues);
    const [supports, setSupports] = useState<SupportObjectType>(secondFormDefaultTransmissionValues);
    const [amount, setAmount] = useState<subscriptionObjectType>(secondFormDefaultSubscriptionPriceValues);
    // Third Form 
    const [paymentCollectionType, setPaymentCollectionType] = useState<string>("récurrent");

    // YUP config
    const { // First Form 
        register,
        reset,
        formState: { errors, isDirty, isValid },
        trigger,
        resetField,
        getValues,
        setValue,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(contractFirstFormValidationSchema)
    });
    const { // Second Form 
        register: registerForm2,
        reset: resetForm2,
        formState: { errors: errorsForm2, isDirty: isDirtyForm2, isValid: isValidForm2 },
        trigger: triggerForm2,
        setError: setError2,
        getValues: getValues2,
        setValue: setValue2
    } = useForm({
        mode: 'all',
        resolver: yupResolver(secondFormValidationSchema)
    });
    const { // Third Form 
        register: registerForm3,
        reset: resetForm3,
        formState: { errors: errorsForm3, isDirty: isDirtyForm3, isValid: isValidForm3 },
        trigger: triggerForm3,
        setError: setError3,
        getValues: getValues3,
        setValue: setValue3
    } = useForm({
        mode: 'all',
        resolver: yupResolver(contractThirdFormValidationSchema)
    });


    // Functions 
    function fowardButtonIsActif() {
        switch (props.tabIndex) {
            case 1:
                return (isDirty && isValid);
            case 2:
                return (isDirty && isValid);
            case 3:
                return (isDirtyForm3 && isValidForm3);
            case 4:
                return true;
            default:
                break;
        }
    }
    function getTrigger() {
        switch (props.tabIndex) {
            case 1:
                return trigger;
            case 2:
                return triggerForm2;
            case 3:
                return triggerForm3;
            default:
                break;
        }
    }
    function displayForm(tabIndex: number) {
        const title = `${props.data[props.tabIndex - 1].index}. ${props.data[props.tabIndex - 1].title}`;
        switch (tabIndex) {
            case 1:
                if (!isImportLoading)
                    return <FirstFormContainer
                        register={register}
                        errors={errors}
                        title={title}
                        setImportDialogIsOpen={handleClickOpenImportModal}
                        setIsCommunicationConsentOk={setIsCommunicationConsentOk}
                    />
                else
                    return <AnimatedSkeletonFirstForm />

            case 2:
                return <SecondFormContainer
                    register={registerForm2}
                    errors={errorsForm2}
                    title={title}
                    checkBoxes={checkboxes}
                    setCheckBoxes={setCheckBoxes}
                    supports={supports}
                    setSupports={setSupports}
                    trigger={triggerForm2}
                    amount={amount}
                    setAmount={setAmount}
                />

            case 3:
                return <ThirdFormContainer
                    register={registerForm3}
                    errors={errorsForm3}
                    title={title}
                    paymentCollectionType={paymentCollectionType}
                    setPaymentCollectionType={setPaymentCollectionType}
                />

            default:
                return <FirstFormContainer
                    register={register}
                    errors={errors}
                    title={title}
                    setImportDialogIsOpen={handleClickOpenImportModal}
                    setIsCommunicationConsentOk={setIsCommunicationConsentOk}
                />
        }
    }

    // Handlers 
    const handleValidate = () => {

        setIsValidationLoading(true);
        const formData = {
            type: "contrat",
            firstForm: {
                siteName: getValues('siteName'),
                siteAddress: getValues('siteAddress'),
                siteCity: getValues('siteCity'),
                sitePostalCode: getValues('sitePostalCode'),
                siteFacturationName: getValues('siteFacturationName'),
                siteFacturationAddress: getValues('siteFacturationAddress'),
                siteFacturationCity: getValues('siteFacturationCity'),
                siteFacturationPostalCode: getValues('siteFacturationPostalCode'),
                userName: getValues('userName'),
                userFirstName: getValues('userFirstName'),
                userEmail: getValues('userEmail'),
                sitePhoneNumber: getValues('sitePhoneNumber'),
                consentCom: isCommunicationConsentOk
            },
            secondForm: {
                supportTransmission: supports,
                ...checkboxes,
                ...amount
            },
            thirdForm: {
                uniqueMandateRef: getValues3("uniqueMandateRef"),
                mandateObject: getValues3("mandateObject"),
                deptorName: getValues3("deptorName"),
                deptorAddress: getValues3("deptorAddress"),
                deptorPC: getValues3("deptorPC"),
                deptorCity: getValues3("deptorCity"),
                deptorIBAN: getValues3("deptorIBAN"),
                deptorBICCode: getValues3("deptorBICCode"),
                paymentCollectionType: paymentCollectionType
            }
        };

        // Send informations to server
        axios.post(`${process.env.REACT_APP_SERVER_URL}demande/add`, formData)
            .then((data) => {
                setIsValidationLoading(false);
                setValidationErrorOccured(false);
                setValidateModalIsOpen(false);
                setIsValidateOk(true);
            })
            .catch((error) => {
                setIsValidationLoading(false);
                setValidationErrorOccured(true);
            });

        // console.log(formData);
    }
    const handleValidateModalOpen = () => {
        if (fowardButtonIsActif()) {
            setValidateModalIsOpen(true);
        } else {
            triggerForm3();
        }
    }
    const handleValidateModalClose = () => {
        setValidationErrorOccured(false);
        setValidateModalIsOpen(false);
    }
    const handleRedirectModalClose = () => {
        navigate("/")
        // window.location.replace(`${process.env.REACT_APP_SITE_OPTI}`);
    }
    const handleClickOpenImportModal = () => {
        setImportModalIsOpen(true);
    };
    const handleCloseImportModal = () => {
        setImportDataErrorStatus(0);
        setImportModalIsOpen(false);
    };
    const handleImportData = (event: React.FormEvent<HTMLFormElement>) => {
        setIsImportLoading(true);
        setImportDataErrorStatus(0);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const ref = formJson.ref;
        // get informations from server
        axios.get(`${process.env.REACT_APP_SERVER_URL}demande/get?ref=${ref}&type=contrat`)
            .then((data) => {
                handleCloseImportModal();
                // set all form values 
                handleInitFormData(data.data);
                const timerId = setTimeout(() => { // loading timeout
                    setIsImportLoading(false);
                }, 2000);
            })
            .catch((error) => {
                if (error.response) {
                    setIsImportLoading(false);
                    setImportDataErrorStatus(1);
                } else {
                    setIsImportLoading(false);
                    setImportDataErrorStatus(2);
                }
            });
    }

    const handleInitFormData = (formData: any) => {
        // first form
        setValue("siteName", formData.firstForm.siteName, { shouldValidate: true, shouldDirty: true });
        setValue("siteAddress", formData.firstForm.siteAddress, { shouldValidate: true, shouldDirty: true });
        setValue("siteCity", formData.firstForm.siteCity, { shouldValidate: true, shouldDirty: true });
        setValue("sitePostalCode", formData.firstForm.sitePostalCode, { shouldValidate: true, shouldDirty: true });

        setValue("siteFacturationName", formData.firstForm.siteFacturationName, { shouldValidate: true, shouldDirty: true });
        setValue("siteFacturationAddress", formData.firstForm.siteFacturationAddress, { shouldValidate: true, shouldDirty: true });
        setValue("siteFacturationCity", formData.firstForm.siteFacturationCity, { shouldValidate: true, shouldDirty: true });
        setValue("siteFacturationPostalCode", formData.firstForm.siteFacturationPostalCode, { shouldValidate: true, shouldDirty: true });

        setValue("userName", formData.firstForm.userName, { shouldValidate: true, shouldDirty: true });
        setValue("userFirstName", formData.firstForm.userFirstName, { shouldValidate: true, shouldDirty: true });
        setValue("userEmail", formData.firstForm.userEmail, { shouldValidate: true, shouldDirty: true });
        setValue("sitePhoneNumber", formData.firstForm.sitePhoneNumber, { shouldValidate: true, shouldDirty: true });
        setIsCommunicationConsentOk(formData.firstForm.consentCom);

        // second form
        setCheckBoxes({
            intrusionDetection: formData.secondForm.intrusionDetection,
            fireDetection: formData.secondForm.fireDetection,
            assaultAlert: formData.secondForm.assaultAlert,
            technicalAlarms: formData.secondForm.technicalAlarms,
            MESSchedules: formData.secondForm.MESSchedules,
            technicalSystemsDefaults: formData.secondForm.technicalSystemsDefaults,
        });
        setSupports({
            transmissionPrincipale: formData.secondForm.supportTransmission.transmissionPrincipale,
            transmissonSecondaire: formData.secondForm.supportTransmission.transmissonSecondaire,
            niveauTransmission: formData.secondForm.supportTransmission.niveauTransmission,
            fournitureSim: formData.secondForm.supportTransmission.fournitureSim,
            leveeDouteAudio: formData.secondForm.supportTransmission.leveeDouteAudio,
            leveeDouteVideo: formData.secondForm.supportTransmission.leveeDouteVideo,
            interventionAlarme: formData.secondForm.supportTransmission.interventionAlarme,
            GestionMoyenAcces: formData.secondForm.supportTransmission.GestionMoyenAcces,
        });
        setAmount({
            prixHT: formData.secondForm.prixHT,
            prixTTC: formData.secondForm.prixTTC
        });
    }

    return (
        <div className="form-container">
            {displayForm(props.tabIndex)}
            <NavigationButtons
                // && (tableData.length > 0)
                fowardButtonIsActif={fowardButtonIsActif}
                selectedTabIndex={props.tabIndex}
                totalNumberOfTabs={props.data.length}
                setTabIndex={props.setTabIndex}
                trigger={getTrigger()}
                handleValidate={handleValidateModalOpen}
            />
            <ValidateChoiceModalModal
                isLoading={isValidationLoading}
                isOpen={validateModalIsOpen}
                handleClose={handleValidateModalClose}
                handleOk={handleValidate}
                bodyText={
                    <>
                        Êtes-vous sûr de vouloir valider ces données ?
                        <FormHelperText sx={{ mt: 3 }}> Nous vous conseillons de vérifier vos saisies avant de valider l'envoi. </FormHelperText>
                        {validationErrorOccured &&
                            <FormHelperText error={validationErrorOccured}> Une erreur est survenue. Veuillez contacter le service technique.</FormHelperText>
                        }
                    </>
                }
                title={
                    <>
                        Soumission du formulaire
                        {isValidationLoading && <CircularProgress disableShrink size=".8rem" sx={{ ml: 2 }} />}
                    </>
                }
            />

            <RedirectModal
                handleClose={handleRedirectModalClose}
                handleOk={handleRedirectModalClose}
                isOpen={isValidateOk}
            />

            {/* Import data dialog */}
            <FormImportDialog
                open={importModalIsOpen}
                handleClickOpen={handleClickOpenImportModal}
                handleClose={handleCloseImportModal}
                handleSubmit={handleImportData}
                importDataErrorStatus={importDataErrorStatus}
                isLoading={isImportLoading}
            />
        </div>
    );
}

export default ContractFormContainer;