import '../form-container.css'
import dayjs, { Dayjs } from 'dayjs';
import { Checkbox, ClickAwayListener, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { horaireOuvertureContent } from '../../../utils/HelpToolTipContents';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getDayIndex, orderedSelectedDays, thirdFormDays, thirdFormFieldTitles, thirdFormFieldTooltips } from '../../../utils/FormDataDefaultValues';
import { HelpToolTip } from '../../utils/HelpToolTips';
import { TimeSchedule } from '../../utils/TimeSchedule';
import { CustomRadioGroup } from '../../utils/RadioGroup';
import { AddCircle } from '@mui/icons-material';
import AddScheduleDialog from './modals/AddScheduleDialog';
import BasicTable from '../../tables/ScheduleTable';
import ScheduleTable from '../../tables/ScheduleTable';
import ValidateChoiceModalModal from './modals/ValidateChoiceModal';
import ModifyScheduleDialog from './modals/ModifyScheduleDialog';

type ThirdFormPropsTypes = {
    title: string,
    dateValues: Array<Array<Dayjs | null>>,
    setDateValues: Function,
    hasOpenningHours: boolean,
    setHasOpenningHours: Function,
    selectedDays: string[],
    setSelectedDays: Function,
    tableDataError: boolean,
    tableData: Array<ScheduleTableData>,
    setTableDataError: Function,
    deactivatedDays: string[],
    setDeactivatedDays: Function,
    setTableData: Function,
}
export type ScheduleTableData = {
    jours: string,
    horaires: Array<Dayjs>,
}

export const ThirdFormContainer2 = ({
    title,
    dateValues,
    setDateValues,
    hasOpenningHours,
    setHasOpenningHours,
    selectedDays,
    setSelectedDays,
    tableData,
    tableDataError,
    setTableDataError,
    deactivatedDays,
    setDeactivatedDays,
    setTableData
}: ThirdFormPropsTypes) => {
    // constants 
    const tableHeader = ["#", "Jours", "Horaires", "actions"];

    // States 
    const [openToolTip, setOpenToolTip] = useState(-1);
    const [addScheduleModalIsOpen, setAddSheduleModalIsOpen] = useState<boolean>(false);
    // const [deactivatedDays, setDeactivatedDays] = useState<string[]>([]); // when a day has already been scheduled, it is deactivated
    const [deleteModalState, setDeleteModalState] = useState({
        open: false,
        index: -1,
        name: ""
    });
    const [modificationModalStatus, setModificationModalStatus] = useState({
        open: false,
        index: -1,
        selected: { jours: "", horaires: [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')] }
    });

    // Handlers
    const handleShowToolClose = (val: number) => {
        if (openToolTip === val) {
            setOpenToolTip(-1);
        }
    };
    const handleShowToolOpen = (val: number) => {
        setOpenToolTip(val);
    };
    const handleOpenAddShcheduleModal = () => {
        setAddSheduleModalIsOpen(true);
    };
    const handleOpenModifyShcheduleModal = (scheduleIndex: number) => {
        // set modified element selected days 
        var reActivatedDays = tableData[scheduleIndex].jours.split(',').map(item => item.trim().toUpperCase());
        setDeactivatedDays((prev: string[]) => orderedSelectedDays((prev.filter((p) => (!reActivatedDays.includes(p))))))
        setSelectedDays(reActivatedDays);

        setModificationModalStatus({
            open: true,
            index: scheduleIndex,
            selected: tableData[scheduleIndex]
        });
    };
    const handleCloseModifyShcheduleModal = () => {
        // deactivating selected schedule days
        var reActivatedDays = tableData[modificationModalStatus.index].jours.split(',').map(item => item.trim().toUpperCase());
        setDeactivatedDays((prev: string[]) => orderedSelectedDays([...prev, ...reActivatedDays]));
        setSelectedDays([]);

        setModificationModalStatus({
            open: false,
            index: -1,
            selected: { jours: "", horaires: [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')] }
        });
    };
    const handleCloseAddShcheduleModal = () => {
        // setImportDataErrorStatus(0);
        setAddSheduleModalIsOpen(false);
    };
    const handleValidateDelete = () => {
        let filteredData = tableData.filter((_, i) => i !== deleteModalState.index)
        setTableData(filteredData);
        setDeleteModalState({
            open: false,
            index: -1,
            name: ""
        });
        if (filteredData.length === 0) {
            setTableDataError(true);
        }

        // updating the deactivate days array
        var reActivatedDays = tableData[deleteModalState.index].jours.split(',').map(item => item.trim().toUpperCase());
        setDeactivatedDays((prev: string[]) => orderedSelectedDays((prev.filter((p) => (!reActivatedDays.includes(p))))))
    }
    const handleCloseDeleteModal = () => {
        setDeleteModalState({
            open: false,
            index: -1,
            name: ""
        });
    }
    const handleOpenDeleteModal = (scheduleIndex: number) => {
        setDeleteModalState({
            open: true,
            index: scheduleIndex,
            name: `${tableData[scheduleIndex].jours}`
        });
    }
    const handleValidateAddSchedule = (schedules: any) => {
        const changedDateValues = dateValues;
        var selectedDaysString = selectedDays.length == 7 ? "Tous les jours" : "";
        selectedDays.forEach((day, i) => {
            var index = getDayIndex(day);
            changedDateValues[index] = [...schedules];
            if (selectedDays.length != 7)
                selectedDaysString += (i != 0 ? ", " : "") + day.toLowerCase();
        });
        setDateValues([...changedDateValues]);
        setTableData((prev: Array<ScheduleTableData>) => [
            ...prev,
            {
                jours: selectedDaysString,
                horaires: schedules
            }
        ]);
        setTableDataError(false);
        // updating the deactivated days list
        setDeactivatedDays(orderedSelectedDays([...deactivatedDays, ...selectedDays]));
        // re-initialising selected days 
        setSelectedDays([]);
        // closing the modal
        handleCloseAddShcheduleModal();
    }
    const handleValidateModifySchedule = (schedules: any) => {
        const changedDateValues = dateValues;
        var selectedDaysString = selectedDays.length == 7 ? "Tous les jours" : "";
        // updating date values
        selectedDays.forEach((day, i) => {
            var index = getDayIndex(day);
            changedDateValues[index] = [...schedules];
            if (selectedDays.length != 7)
                selectedDaysString += (i != 0 ? ", " : "") + day.toLowerCase();
        });
        setDateValues([...changedDateValues]);

        // updating table data 
        var updateTableData = tableData;
        updateTableData[modificationModalStatus.index] = {
            jours: selectedDaysString, horaires: schedules
        };
        setTableData([...updateTableData]);

        setTableDataError(false);
        // updating the deactivated days list
        setDeactivatedDays(orderedSelectedDays([...deactivatedDays, ...selectedDays]));
        // re-initialising selected days 
        setSelectedDays([]);
        // closing the modal
        setModificationModalStatus({
            open: false,
            index: -1,
            selected: { jours: "", horaires: [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')] }
        });
    }
    const handleRadioChange = () => {
        setHasOpenningHours((prev: boolean) => !prev);
    }
    // useEffects 
    useEffect(() => {
        // scroll back to the top of the card
        const body = document.querySelector('#scrolled-card');
        body?.scrollIntoView({
            behavior: 'smooth'
        });
        // console.log("disabled days", deactivatedDays, "\n selected days : ", selectedDays, "\n date values", dateValues);
    }, [])

    return (
        <div className="form-card" id='scrolled-card'>

            {/* Header */}
            <div className="heading">
                {title}
            </div>

            <div className="sub-heading">
                Avec horaires d'ouverture
                <ClickAwayListener onClickAway={() => handleShowToolClose(3)}>
                    <div>
                        <HelpToolTip
                            content={horaireOuvertureContent}
                            handleShowToolClose={() => handleShowToolClose(3)}
                            handleShowToolOpen={() => handleShowToolOpen(3)}
                            openToolTip={openToolTip === 3}
                        />
                    </div>
                </ClickAwayListener>
            </div>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2} mt={0} mb={5}>
                <Grid item xs={6} md={3} sx={{ mb: 5 }}>
                    <CustomRadioGroup
                        title=''
                        data={["Oui", "Non"]}
                        defaultValue={hasOpenningHours ? 'Oui' : 'Non'}
                        onChange={handleRadioChange}
                        property={2}
                    />
                </Grid>
                {hasOpenningHours &&
                    <>
                        <Grid item xs={0} sx={{ mb: 5 }}>
                            <IconButton
                                aria-label="ajouter des horaires"
                                onClick={handleOpenAddShcheduleModal}
                                disabled={deactivatedDays.length == 7}
                                color='primary'
                            >
                                <AddCircle />
                            </IconButton>
                        </Grid>
                        <ScheduleTable
                            error={tableDataError}
                            data={tableData}
                            handleOpenDeleteModal={handleOpenDeleteModal}
                            handleOpenModificationModal={handleOpenModifyShcheduleModal}
                            header={tableHeader}
                        />
                    </>

                }
            </Grid>
            <AddScheduleDialog
                open={addScheduleModalIsOpen}
                handleClose={handleCloseAddShcheduleModal}
                handleValidate={handleValidateAddSchedule}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                deactivateDays={deactivatedDays}
            />
            <ModifyScheduleDialog
                open={modificationModalStatus.open}
                handleClose={handleCloseModifyShcheduleModal}
                handleValidate={handleValidateModifySchedule}
                selectedDays={selectedDays}
                setSelectedDays={setSelectedDays}
                deactivateDays={deactivatedDays}
                moifyStatus={modificationModalStatus}
            />
            {/* Delete planning modal */}
            <ValidateChoiceModalModal
                isLoading={false}
                isOpen={deleteModalState.open}
                handleClose={handleCloseDeleteModal}
                handleOk={handleValidateDelete}
                bodyText={<>Êtes-vous sûr de vouloir supprimer le planning de :  <strong>{deleteModalState.name}</strong> ?</>}
                title={<>Suppression d'un planning</>}
            />
        </div >
    );
}