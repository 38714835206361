import "./form-container.css"
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { TableData } from "../tables/ContactTable";
import { firstFormValidationSchema, secondFormValidationSchema } from "../../utils/YupConfigurations";
import dayjs, { Dayjs } from "dayjs";
import { fourthFormDefaultData, fourthFormDefaultSectionsTitles, fourthFormDefaultTitlesFields, fourthFormSelectValues, getSelectedDateValues, secondFormDefaultCheckBoxValue, thirdFormDays, thirdFormDefaultDateValues, thirdFormDefaultDateValues2, thirdFormFieldTitles } from "../../utils/FormDataDefaultValues";
import { CircularProgress, FormHelperText } from "@mui/material";
import axios from "axios";
import { AnimatedSkeletonFirstForm } from "../loading_screen/animated_skeleton_first_form";
import { CheckBoxesType, SecondFormContainer, SupportObjectType } from "./forms/SecondForm";
import { FourthFormContainer, SelectObjectType } from "./forms/FourthForm";
import FirstFormContainer from "./forms/FirstForm";
import { ThirdFormContainer } from "./forms/ThirdForm";
import ValidateChoiceModalModal from "./forms/modals/ValidateChoiceModal";
import RedirectModal from "./forms/modals/RedirectModal";
import FormImportDialog from "./forms/modals/ImportFormDialog";
import { TitleItemProps } from "./TitleItem";
import NavigationButtons from "../navigation_buttons/NavigationButtons";
import { useNavigate } from "react-router-dom";
import { ScheduleTableData, ThirdFormContainer2 } from "./forms/ThirdForm_2";

type FirstContainerProps = {
    data: Array<TitleItemProps>,
    tabIndex: number,
    setTabIndex: Function
}

function InstructionsFormContainer(props: FirstContainerProps) {

    const navigate = useNavigate();
    // States
    const [validationErrorOccured, setValidationErrorOccured] = useState<boolean>(false);
    const [validateModalIsOpen, setValidateModalIsOpen] = useState<boolean>(false);
    const [isValidationLoading, setIsValidationLoading] = useState<boolean>(false);
    const [importModalIsOpen, setImportModalIsOpen] = useState<boolean>(false);
    const [importDataErrorStatus, setImportDataErrorStatus] = useState(0); // 0 : no error, 1 : incorrect id, 2: server error 
    const [isValidateOk, setIsValidateOk] = useState<boolean>(false);
    // First Form 
    const [tableData, setTableData] = useState<Array<TableData>>([]);
    const [tableDataError, setTableDataError] = useState<boolean>(false);
    const [isImportLoading, setIsImportLoading] = useState<boolean>(false);
    // Second Form
    const [supports, setSupports] = useState<SupportObjectType>(
        {
            transmissionPrincipale: "ADSL/SDSL/Fibre",
            frequencePeriodique1: "24 heures",
            transmissonSecondaire: "Aucun",
            frequencePeriodique2: "24 heures"
        });
    const [checkboxes, setCheckBoxes] = useState<CheckBoxesType>(secondFormDefaultCheckBoxValue);
    // Third Form
    const [hasOpenningHours, setHasOpenningHours] = useState<boolean>(false);
    const [selectedDays, setSelectedDays] = useState<string[]>(thirdFormDays);
    const [dateValues, setDateValues] = useState<Array<Array<Dayjs | null>>>(thirdFormDefaultDateValues2);
    const [scheduleTableData, setScheduleTableData] = useState<Array<ScheduleTableData>>([]);
    const [scheduleTableDataError, setScheduleTableDataError] = useState<boolean>(false);
    const [deactivatedDays, setDeactivatedDays] = useState<string[]>([]); // when a day has already been scheduled, it is deactivated
    // Fourth Form
    const [selectData, setSelectData] = useState<Array<SelectObjectType>>(fourthFormSelectValues);

    // YUP config
    const { // First Form 
        register,
        reset,
        formState: { errors, isDirty, isValid },
        trigger,
        resetField,
        getValues,
        setValue,
    } = useForm({
        mode: 'all',
        resolver: yupResolver(firstFormValidationSchema)
    });
    const { // Second Form 
        register: registerForm2,
        reset: resetForm2,
        formState: { errors: errorsForm2, isDirty: isDirtyForm2, isValid: isValidForm2 },
        trigger: triggerForm2,
        setError: setError2,
        getValues: getValues2,
        setValue: setValue2
    } = useForm({
        mode: 'all',
        resolver: yupResolver(secondFormValidationSchema)
    });


    // Functions 
    function fowardButtonIsActif() {
        switch (props.tabIndex) {
            case 1:
                return (isDirty && isValid && tableData.length > 0);
            case 2:
                return (!checkboxes.optionSouscrite || (!checkboxes.avecMoyen && !errorsForm2.contactDetails) || (!errorsForm2.contactDetails && !errorsForm2.systemCode));
            case 3:
                // console.log("selected days : ", selectedDays, "\n date values : ", dateValues, "\n schedule table :", scheduleTableData, "\n deactivated days :", deactivatedDays);
                return (!hasOpenningHours || scheduleTableData.length > 0);
            case 4:
                return true;
            default:
                break;
        }
    }
    function getTrigger() {
        switch (props.tabIndex) {
            case 1:
                return () => {
                    if (tableData.length < 1)
                        setTableDataError(true);
                    trigger();
                };
            case 2:
                return triggerForm2;
            case 3:
                return () => {
                    if (hasOpenningHours && scheduleTableData.length < 1)
                        setScheduleTableDataError(true);
                };
            case 4:
                return () => { };
            default:
                break;
        }
    }
    function displayForm(tabIndex: number) {
        const title = `${props.data[props.tabIndex - 1].index}. ${props.data[props.tabIndex - 1].title}`;
        switch (tabIndex) {
            case 1:
                if (!isImportLoading)
                    return <FirstFormContainer
                        register={register}
                        errors={errors}
                        tableData={tableData}
                        setTableData={setTableData}
                        title={title}
                        tableDataError={tableDataError}
                        setTableDataError={setTableDataError}
                        setImportDialogIsOpen={handleClickOpenImportModal}
                    />
                else
                    return <AnimatedSkeletonFirstForm />

            case 2:
                return <SecondFormContainer
                    register={registerForm2}
                    errors={errorsForm2}
                    title={title}
                    supports={supports}
                    setSupports={setSupports}
                    checkBoxes={checkboxes}
                    trigger={triggerForm2}
                    setCheckBoxes={setCheckBoxes} />

            case 3:
                return <ThirdFormContainer2
                    title={title}
                    dateValues={dateValues}
                    setDateValues={setDateValues}
                    hasOpenningHours={hasOpenningHours}
                    setHasOpenningHours={setHasOpenningHours}
                    selectedDays={selectedDays}
                    setSelectedDays={setSelectedDays}
                    tableDataError={scheduleTableDataError}
                    setTableDataError={setScheduleTableDataError}
                    tableData={scheduleTableData}
                    setTableData={setScheduleTableData}
                    deactivatedDays={deactivatedDays}
                    setDeactivatedDays={setDeactivatedDays}
                />

            case 4:
                return <FourthFormContainer
                    title={title}
                    data={selectData}
                    setData={setSelectData}
                    titlesChamps={fourthFormDefaultTitlesFields}
                    titlesSections={fourthFormDefaultSectionsTitles(hasOpenningHours)}
                    hasOpenningHours={hasOpenningHours}
                />

            default:
                return <FirstFormContainer
                    register={register}
                    errors={errors}
                    tableData={tableData}
                    setTableData={setTableData}
                    title={title}
                    tableDataError={tableDataError}
                    setTableDataError={setTableDataError}
                    setImportDialogIsOpen={handleClickOpenImportModal}
                />
        }
    }

    // Handlers 
    const handleValidate = () => {
        setIsValidationLoading(true);
        var dateValuesData = hasOpenningHours ? getSelectedDateValues(deactivatedDays, dateValues, scheduleTableData) : [[], []];
        const formData = {
            type: "consignes",
            firstForm: {
                userName: getValues('userName'),
                userFirstName: getValues('userFirstName'),
                userEmail: getValues('userEmail'),
                sitesiteName: getValues('siteName'),
                address: getValues('address'),
                city: getValues('city'),
                phoneNumber: getValues('sitePhoneNumber'),
                postalCode: getValues('postalCode'),
                constraintSentence: getValues('constraintSentence'),
                contactPersons: tableData,
            },
            secondForm: {
                supportTransmission: supports,
                ...checkboxes,
                systemCode: getValues2('systemCode'),
                contactDetails: getValues2('contactDetails')
            },
            thirdForm: {
                dateValues: dateValuesData[0],
                tableItemIndices: dateValuesData[1],
                thirdFormFieldTitles: hasOpenningHours ? thirdFormFieldTitles : [],
                selectedDays: hasOpenningHours ? deactivatedDays : []
            },
            fourthForm: {
                sectionsTitles: fourthFormDefaultSectionsTitles(hasOpenningHours),
                data: hasOpenningHours ? selectData : selectData.filter((_, index) => (index < 4 || index > 6))
            }
        };

        // console.log(formData);
        // Send informations to server
        axios.post(`${process.env.REACT_APP_SERVER_URL}demande/add`, formData)
            .then((data) => {
                setIsValidationLoading(false);
                setValidationErrorOccured(false);
                setValidateModalIsOpen(false);
                setIsValidateOk(true);
            })
            .catch((error) => {
                setIsValidationLoading(false);
                setValidationErrorOccured(true);
            });
    }
    const handleInitFormData = (formData: any) => {
        try {
            // // first form
            setValue("userName", formData.firstForm.userName, { shouldValidate: true, shouldDirty: true });
            setValue("userFirstName", formData.firstForm.userFirstName, { shouldValidate: true, shouldDirty: true });
            setValue("userEmail", formData.firstForm.userEmail, { shouldValidate: true, shouldDirty: true });
            setValue("siteName", formData.firstForm.siteName, { shouldValidate: true, shouldDirty: true });
            setValue("address", formData.firstForm.address, { shouldValidate: true, shouldDirty: true });
            setValue("city", formData.firstForm.city, { shouldValidate: true, shouldDirty: true });
            setValue("postalCode", formData.firstForm.postalCode, { shouldValidate: true, shouldDirty: true });
            setValue("sitePhoneNumber", formData.firstForm.sitePhoneNumber, { shouldValidate: true, shouldDirty: true });
            setValue("constraintSentence", formData.firstForm.constraintSentence, { shouldValidate: true, shouldDirty: true });
            setTableData([...formData.firstForm.contactPersons.map((person: any) => ({ ...person, c: person.c == 1 ? true : false }))]);

            // second form
            setValue2("systemCode", formData.secondForm.systemCode, { shouldValidate: true, shouldDirty: true });
            setValue2("contactDetails", formData.secondForm.contactDetails, { shouldValidate: true, shouldDirty: true });
            setSupports({
                transmissionPrincipale: formData.secondForm.supportTransmission.transmissionPrincipale,
                frequencePeriodique1: formData.secondForm.supportTransmission.frequencePeriodique1,
                transmissonSecondaire: formData.secondForm.supportTransmission.transmissonSecondaire,
                frequencePeriodique2: formData.secondForm.supportTransmission.frequencePeriodique2
            });
            setCheckBoxes({
                optionSouscrite: formData.secondForm.optionSouscrite == 1,
                avecMoyen: formData.secondForm.avecMoyen == 1,
                leveeDouteAudio: formData.secondForm.leveeDouteAudio == 1,
                leveeDouteVideo: formData.secondForm.leveeDouteVideo == 1
            });

            // third form
            setHasOpenningHours(formData.thirdForm.dateValues.length != 0);
            setDeactivatedDays(formData.thirdForm.selectedDays.length != 0 ? formData.thirdForm.selectedDays : thirdFormDays);
            if (formData.thirdForm.thirdFormFieldTitles.length > thirdFormFieldTitles.length) {
                for (var i = 2; i < formData.thirdForm.thirdFormFieldTitles.length; i++) {
                    thirdFormFieldTitles.push(formData.thirdForm.thirdFormFieldTitles[i]);
                }
            }
            setDateValues(() => {
                var newDates = thirdFormDefaultDateValues2.map((value, index) => formData.thirdForm.dateValues[index] ? formData.thirdForm.dateValues[index] : value);
                return newDates;
            });
            // managing Schedule table
            var planningIndeces = formData.thirdForm.tableItemIndices;
            var selectedDays = formData.thirdForm.selectedDays;
            var planningTableData: Array<ScheduleTableData> = [];
            selectedDays.forEach((day: string, index: number) => {
                if (planningTableData[planningIndeces[index]]) {
                    planningTableData[planningIndeces[index]].jours += `, ${day.toLowerCase()}`;
                } else {
                    planningTableData[planningIndeces[index]] = { jours: day.toLowerCase(), horaires: [] };
                }
                planningTableData[planningIndeces[index]].horaires = formData.thirdForm.dateValues[index].map((d: string) => (dayjs(d)));
            });
            setScheduleTableData(planningTableData);

            // fourth form
            // setSelectData(fourthFormDefaultData(formData.thirdForm.dateValues.length != 0, formData.fourthForm.data));
            setSelectData(fourthFormDefaultData(formData.fourthForm.data));
            console.log(formData);
        } catch (error: any) {
            console.error(`Error: ${error.message}`);
        }
    }
    const handleValidateModalOpen = () => {
        setValidateModalIsOpen(true);
    }
    const handleValidateModalClose = () => {
        setValidationErrorOccured(false);
        setValidateModalIsOpen(false);
    }
    const handleRedirectModalClose = () => {
        navigate("/");
        // window.location.replace(`${process.env.REACT_APP_SITE_OPTI}`);
    }
    const handleClickOpenImportModal = () => {
        setImportModalIsOpen(true);
    };
    const handleCloseImportModal = () => {
        setImportDataErrorStatus(0);
        setImportModalIsOpen(false);
    };
    const handleImportData = (event: React.FormEvent<HTMLFormElement>) => {
        setIsImportLoading(true);
        setImportDataErrorStatus(0);
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries((formData as any).entries());
        const ref = formJson.ref;
        // get informations from server
        axios.get(`${process.env.REACT_APP_SERVER_URL}demande/get?ref=${ref}&type=consignes`)
            .then((data) => {
                handleCloseImportModal();
                // set all form values 
                handleInitFormData(data.data);
                const timerId = setTimeout(() => { // loading timeout
                    setIsImportLoading(false);
                }, 2000);
            })
            .catch((error) => {
                if (error.response) {
                    setIsImportLoading(false);
                    setImportDataErrorStatus(1);
                } else {
                    setIsImportLoading(false);
                    setImportDataErrorStatus(2);
                }
            });
    }

    return (
        <div className="form-container">
            {displayForm(props.tabIndex)}
            <NavigationButtons
                // && (tableData.length > 0)
                fowardButtonIsActif={fowardButtonIsActif}
                selectedTabIndex={props.tabIndex}
                totalNumberOfTabs={props.data.length}
                setTabIndex={props.setTabIndex}
                trigger={getTrigger()}
                handleValidate={handleValidateModalOpen}
            />
            <ValidateChoiceModalModal
                isLoading={isValidationLoading}
                isOpen={validateModalIsOpen}
                handleClose={handleValidateModalClose}
                handleOk={handleValidate}
                bodyText={
                    <>
                        Êtes-vous sûr de vouloir valider ces données ?
                        <FormHelperText sx={{ mt: 3 }}> Nous vous conseillons de vérifier vos saisies avant de valider l'envoi. </FormHelperText>
                        {validationErrorOccured &&
                            <FormHelperText error={validationErrorOccured}> Une erreur est survenue. Veuillez contacter le service technique.</FormHelperText>
                        }
                    </>
                }
                title={
                    <>
                        Soumission du formulaire
                        {isValidationLoading && <CircularProgress disableShrink size=".8rem" sx={{ ml: 2 }} />}
                    </>
                }
            />
            <RedirectModal
                handleClose={handleRedirectModalClose}
                handleOk={handleRedirectModalClose}
                isOpen={isValidateOk}
            />

            {/* Import data dialog */}
            <FormImportDialog
                open={importModalIsOpen}
                handleClickOpen={handleClickOpenImportModal}
                handleClose={handleCloseImportModal}
                handleSubmit={handleImportData}
                importDataErrorStatus={importDataErrorStatus}
                isLoading={isImportLoading}
            />
        </div>
    );
}

export default InstructionsFormContainer;