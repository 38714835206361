import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditNoteIcon from '@mui/icons-material/EditNote';
import "./table.css"
import { error } from 'console';
import { Dayjs } from 'dayjs';
import { useEffect } from 'react';

type ScheduleTableProps = {
  data: Array<any>,
  header: Array<String>,
  handleOpenDeleteModal: Function,
  handleOpenModificationModal: Function,
  error: boolean
}

export default function ScheduleTable(props: ScheduleTableProps) {
  return (
    <TableContainer component={Paper} className={props.error ? "table-error" : ""}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.header.map((title, index) => (
              <TableCell
                key={title + '-' + index}
                align={index <= 1 ? "left" : "right"}
              // className={props.error ? "error" : ""}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.length > 0 ?
            (
              props.data.map((row, index) => (
                <TableRow
                  key={row.name + '-' + index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    {index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">{row.jours}</TableCell>
                  <TableCell align="right">{
                    row.horaires.map((dt: Dayjs, i: number) => {
                      return `${i % 2 === 0 ? (i !== 0 ? '], [' : '[') : '-'}${dt.format('HH:mm')}`;
                    }).join('') + ']'
                  }</TableCell>
                  <TableCell align="right">
                    <div>
                      <IconButton onClick={() => { props.handleOpenModificationModal(index) }} aria-label="Modifier" color="primary" size='small'>
                        <EditNoteIcon />
                      </IconButton>

                      <IconButton aria-label="Supprimer" onClick={() => { props.handleOpenDeleteModal(index) }} color="error" size='small'>
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="right" colSpan={4}>
                  <span className={`empty-table-text ${(props.error ? "error" : "")}`}>
                    Aucun planning n'a été ajouté.
                  </span>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}