import React from 'react';
import { AnimatedSkeletonFirstForm } from '../loading_screen/animated_skeleton_first_form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import "./model-container.css"
import { Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type ModelContainer = {
    selectedModel: number,
    setSelectedModel: Function
}

export const ModelContainer = ({ selectedModel, setSelectedModel }: ModelContainer) => {
    const navigate = useNavigate();
    return (
        <div className="home-form-container">
            <img src={process.env.PUBLIC_URL + '/logo-big.png'} className='home-logo' />
            <div className="home-heading-container">
                <div className="home-heading">
                    Modèles de formulaires
                    <span className="home-history-button">
                        <IconButton aria-label="historique des documents" onClick={() => navigate("history")}>
                            <WorkHistoryTwoToneIcon color='warning'/>
                        </IconButton>
                    </span>
                </div>
                <span className="home-sub-heading">Veuillez choisir le type de formulaire qui vous intéresse.</span>
            </div>
            <div className="home-flex-row">
                {/* Instructions */}
                <div className={selectedModel != 0 ? "home-model-card" : "home-model-card home-active"} onClick={() => { setSelectedModel(0) }}>
                    <span className="home-select-circle">
                        {selectedModel == 0 && <span className="home-select-indicator"></span>}
                    </span>
                    <img src={process.env.PUBLIC_URL + '/instructions-logo.png'} className='home-card-img' />
                    <div className="home-model-card-title">Consignes de télésurveillance</div>
                </div>
                {/* Contract */}
                <div className={selectedModel != 1 ? "home-model-card" : "home-model-card home-active"} onClick={() => { setSelectedModel(1) }}>
                    <span className="home-select-circle">
                        {selectedModel == 1 && <span className="home-select-indicator"></span>}
                    </span>
                    <img src={process.env.PUBLIC_URL + '/contract-logo.png'} className='home-card-img' />
                    <div className="home-model-card-title">Contrat de télésurveillance</div>
                </div>
            </div>
            <div className="home-btn-container">
                <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    onClick={() => { navigate(selectedModel == 0 ? "/instructions" : "contract") }}
                >
                    Continuer
                </Button>
            </div>
        </div>
    );
};
