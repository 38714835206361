// Instructions form 
// Second form
export const supportContent = {
    title: "Supports de transmission et tests de bon fonctionnement",
    body: <>
        {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
            {"It's very engaging. Right?"} */}
        La mise en place d’un support de transmission secondaire permet, en cas de défaillance du support principal, de maintenir
        la liaison avec la station de télésurveillance par le réseau choisi. Les réseaux mobiles, par définition restent dépendants
        des risques de perturbations pouvant exister dans les liaisons hertziennes
    </>
}

export const interventionsContent = {
    title: "Interventions sur alarme",
    body: <>
        La prise en compte de l’installation sera effective 24 heures après la réception de cette fiche de consignes entièrement
        renseignée et après essais complets de l’installation par le technicien.
    </>
}

export const leveeDouteContent = {
    title: "Levée de doute audio/vidéo",
    body: <>
        Si la levée de doute est négative (écoute blanche ou vidéo sans présence humaine), qu'un seul code d'alarme est reçu
        et que la fin de défaut de l'élément de détection arrive dans les 3 minutes suivant la levée de doute, Le Prestataire ne
        préviendra aucun des correspondants d'alertes, mais il engagera une action immédiate à la réception de toute
        information complémentaire suivant cette alarme.
    </>
}

// Third form
export const horaireOuvertureContent = {
    title: "Horaires d’ouverture",
    body:
        <>
            <p>
                Si la gestion des horaires de mise en service est souscrite, le Client devra obligatoirement téléphoner à la station de
                télésurveillance pour l’informer d’une mise hors service effectuée en dehors des horaires définis ci-dessus. En cas d’oubli
                de mise en service, celle-ci ne lui sera signalée qu’une seule fois. La mise en service ultérieure sera de sa seule
                responsabilité.
            </p>
            <p>
                Le respect des plages horaires est indispensable. En cas d’évolution de celles-ci, le Client doit obligatoirement en informer
                le Prestataire. A défaut, ce dernier est susceptible de modifier les horaires afin qu’ils correspondent à la réalité constatée.
                Le Client sera informé de ces éventuels changements.
            </p>
        </>
}

// Fourth form
export const infosTreatmentContent = {
    title: "Traitement d'informations",
    body:
        <>
            <p>
                <b>Tempo : </b>
                Le traitement de l’information sera temporisé à sa réception pour le nombre de minutes indiquées.
            </p>
            <p>
                <b>Niveau d’alerte :</b> <br />

                <b>Niveau 0 : </b>Le prestataire d’intervention est le seul à être alerté. A l’issue de son intervention un compte rendu est transmis
                au client et les correspondants d’alerte ne seront contactés que si une anomalie est constatée lors de celle-ci. L’option
                intervention sur alarme est obligatoirement retenue.
                <br />

                <b>Niveau 1 : </b>Les correspondants désignés seront contactés dans l’ordre choisi ci-dessus après un contre-appel sur place. Sans
                réponse de leur part, si l’option intervention sur alarme est retenue, c’est le prestataire d’intervention qui sera prévenu. Dans
                le cas contraire, les messages laissés sur les différents répondeurs feront office d’information laissée au client.
                <br />
                <b>Niveau 2 : </b>Les alertes seront traitées en différé. Le site et les correspondants désignés ne seront prévenus
                qu’entre 08h00 et 20h00. Si l’information est reçue en dehors de ces horaires, le traitement sera alors temporisé
                jusqu’au lendemain matin. Si la fin du défaut est reçue entre temps, aucun correspondant ne sera prévenu.
                <br />
                <b>SO : </b> Le type d’alerte n’existe pas.
            </p>
            <p>
                <b>Mode de com : </b>
                L’information sera délivrée aux correspondants d’alerte via le mode de communication choisi en fonction du type
                d’information. Pour les appels téléphoniques, si aucun des correspondants d’alerte n’est joignable, les messages laissés
                sur les différents répondeurs feront office d’information.
            </p>
            <p>
                <b>Inter : </b>
                Si aucun des correspondants d’alertes ne répond suite à la réception d’une information et que l’option intervention sur
                alarme est retenue, un agent de sécurité se rendra sur place pour lever le doute.
            </p>
        </>
}


// Contract form 
// Second form 

export const supportContent2 = {
    title: "Support de transmission",
    body: <>
        {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
            {"It's very engaging. Right?"} */}
        Support utilisé pour la transmission des informations (RTC, GSM/GPRS, ADSL,…).
    </>
}