import * as Yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const phoneRegExp2 = /^((\\s*)|(\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// instructions form yup configurations
export const firstFormValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Ce champ est requis.'),
    userFirstName: Yup.string().required('Ce champ est requis.'),
    userEmail: Yup.string().required('Ce champ est requis').email('Adresse email invalide'),
    siteName: Yup.string().required('Ce champ est requis.'),
    address: Yup.string().required('Ce champ est requis.'),
    city: Yup.string().required('Ce champ est requis'),
    postalCode: Yup.string().required('Ce champ est requis.'),
    sitePhoneNumber: Yup.string(),
    // .matches(phoneRegExp2, 'Le numéro de téléphone n\'est pas valide.'),
    constraintSentence: Yup.string()
});
export const addContactValidationSchema = Yup.object().shape({
    name: Yup.string().required('Ce champ est requis.'),
    firstName: Yup.string()
        .required('Ce champ est requis.'),
    email: Yup.string()
        // .required('Ce champ est requis')
        .email('Adresse email invalide'),
    password: Yup.string()
        .required('Ce champ est requis.'),
    phoneNumber: Yup.string()
        .required('Ce champ est requis.').matches(phoneRegExp, 'Le numéro de téléphone n\'est pas valide.'),
});
export const secondFormValidationSchema = Yup.object().shape({
    systemCode: Yup.string().required('Ce champ est requis.'),
    contactDetails: Yup.string().required('Ce champ est requis.')
});

// contract form yup configurations 
export const contractFirstFormValidationSchema = Yup.object().shape({
    siteName: Yup.string().required('Ce champ est requis.'),
    siteAddress: Yup.string().required('Ce champ est requis.'),
    siteCity: Yup.string().required('Ce champ est requis'),
    sitePostalCode: Yup.string().required('Ce champ est requis.'),

    siteFacturationName: Yup.string(),
    siteFacturationAddress: Yup.string(),
    siteFacturationCity: Yup.string(),
    siteFacturationPostalCode: Yup.string(),

    userName: Yup.string().required('Ce champ est requis.'),
    userFirstName: Yup.string().required('Ce champ est requis.'),
    userEmail: Yup.string().required('Ce champ est requis').email('Adresse email invalide'),
    sitePhoneNumber: Yup.string().required('Ce champ est requis.')
        .matches(phoneRegExp, 'Le numéro de téléphone n\'est pas valide.')
});

export const contractThirdFormValidationSchema = Yup.object().shape({
    uniqueMandateRef: Yup.string().required('Ce champ est requis.'),
    mandateObject: Yup.string().required('Ce champ est requis.'),

    deptorName: Yup.string().required('Ce champ est requis'),
    deptorAddress: Yup.string().required('Ce champ est requis.'),
    deptorPC: Yup.string().required('Ce champ est requis.'),
    deptorCity: Yup.string().required('Ce champ est requis.'),
    deptorIBAN: Yup.string().required('Ce champ est requis.'),
    deptorBICCode: Yup.string().required('Ce champ est requis.')
});