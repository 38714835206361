import dayjs, { Dayjs } from "dayjs"
import { SelectObjectType } from "../components/remote_surveillance_instructions_forms/forms/FourthForm";
import { ScheduleTableData } from "../components/remote_surveillance_instructions_forms/forms/ThirdForm_2";


// instructions form

export const secondFormDefaultCheckBoxValue = {
    optionSouscrite: false,
    avecMoyen: false,
    leveeDouteAudio: false,
    leveeDouteVideo: false
}

export const thirdFormDefaultDateValues = [ // 1 item for each day of the week 
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
    { mhs1: dayjs('2023-11-21T12:00'), mes1: dayjs('2023-11-21T12:00'), mhs2: dayjs('2023-11-21T12:00'), mes2: dayjs('2023-11-21T12:00'), mhs3: dayjs('2023-11-21T12:00'), mes3: dayjs('2023-11-21T12:00'), },
]

export const thirdFormDefaultDateValues2 = [ // 1 item for each day of the week 
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
    [dayjs('2023-11-21T12:00'), dayjs('2023-11-21T12:00')],
]

export const thirdFormFieldTitles = [
    "MHS G1",
    "MES G1"
];

export const thirdFormFieldTooltips = [
    "Groupe 1 : Heure de mise hors service des systèmes",
    "Groupe 1 : Heure de mise en service des systèmes"
];

export const thirdFormDays = [
    "LUNDI",
    "MARDI",
    "MERCREDI",
    "JEUDI",
    "VENDREDI",
    "SAMEDI",
    "DIMANCHE"
];

export const fourthFormDefaultTitlesFields = ["Temporisation", "Niveau d'alerte", "Mode de communication", "Intervention"]

export const fourthFormDefaultSectionsTitles = (isScheduleActive: boolean) => {
    let sections = [
        "Alarme intrusion",
        "Alarme intrusion à la mise hors service",
        "Autoprotection",
        "Agression",
        "Absence de mise en service",
        "Mise hors service hors plage",
        "Absence de mise hors service",
        "Incendie",
        "Défaut secteur",
        "Coupure de ligne téléphonique",
        "Absence de test",
        "Défaut froids",
        "Technique",
    ];
    // if (!isScheduleActive) {
    //     sections = sections.filter(section =>
    //         section !== "Absence de mise en service" &&
    //         section !== "Mise hors service hors plage" &&
    //         section !== "Absence de mise hors service"
    //     );
    // }

    return sections;
}

export const fourthFormDefaultData = (data: Array<SelectObjectType>) => {
    let sections: Array<SelectObjectType> = data;
    // if (!isScheduleActive && sections.length == 13) {
    //     // remove the 3 entries related to the time schedule
    //     sections.splice(4, 3);
    // } else if (isScheduleActive && sections.length == 10) {
    //     // insert the 3 entries related to the time schedule
    //     sections.splice(4, 0,
    //         { tempo: "0", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Oui" },
    //         { tempo: "15", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Non" },
    //         { tempo: "15", niveauAlerte: "SO", modeCom: "E-mail", inter: "Non" },
    //     );
    // }
    if (sections.length == 10) {
        sections.splice(4, 0,
            { tempo: "0", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Oui" },
            { tempo: "15", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Non" },
            { tempo: "15", niveauAlerte: "SO", modeCom: "E-mail", inter: "Non" },
        );
    }
    return sections;
}
export const fourthFormSelectValues = [
    { tempo: "0", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Oui" },
    { tempo: "5", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "0", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Oui" },
    { tempo: "0", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Oui" },
    { tempo: "15", niveauAlerte: "1", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "15", niveauAlerte: "SO", modeCom: "E-mail", inter: "Non" },
    { tempo: "15", niveauAlerte: "SO", modeCom: "E-mail", inter: "Non" },
    { tempo: "0", niveauAlerte: "SO", modeCom: "Appel téléphonique", inter: "Oui" },
    { tempo: "60", niveauAlerte: "2", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "60", niveauAlerte: "2", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "15", niveauAlerte: "2", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "0", niveauAlerte: "SO", modeCom: "Appel téléphonique", inter: "Non" },
    { tempo: "0", niveauAlerte: "SO", modeCom: "Appel téléphonique", inter: "Oui" },
];

export const getSelectedDateValues = (selectedDays: string[], dateValues: Array<Array<Dayjs | null>>, tableData: Array<ScheduleTableData>) => {
    var selectedDateValues: Array<Array<Dayjs | null>> = [];
    var tableItemIndicesArray: Array<number> = []; // used to know in which table item a day falls (useful when initialising the table data during import)
    selectedDays.forEach(days => {
        switch (days) {
            case "LUNDI":
                selectedDateValues.push(dateValues[0]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("LUNDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "MARDI":
                selectedDateValues.push(dateValues[1]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("MARDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "MERCREDI":
                selectedDateValues.push(dateValues[2]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("MERCREDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "JEUDI":
                selectedDateValues.push(dateValues[3]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("JEUDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "VENDREDI":
                selectedDateValues.push(dateValues[4]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("VENDREDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "SAMEDI":
                selectedDateValues.push(dateValues[5]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("SAMEDI"));
                tableItemIndicesArray.push(tableItemIndex);
                break;
            case "DIMANCHE":
                selectedDateValues.push(dateValues[6]);
                var tableItemIndex = tableData.findIndex(obj => obj.jours.split(',').map(item => item.trim().toUpperCase()).includes("DIMANCHE"));
                tableItemIndicesArray.push(tableItemIndex);
                break;

            default:
                break;
        }
    });
    return [selectedDateValues, tableItemIndicesArray];
}

export const getDayIndex = (selectedDay: string) => {
    return thirdFormDays.indexOf(selectedDay);
}

export const orderedSelectedDays = (selectedDays: Array<string>): Array<string> => {
    return thirdFormDays.filter((day) => (selectedDays.indexOf(day) != -1));
};

// contract form 

export const secondFormDefaultCheckValues = {
    intrusionDetection: true,
    fireDetection: false,
    assaultAlert: false,
    technicalAlarms: false,
    MESSchedules: false,
    technicalSystemsDefaults: true,
}

export const secondFormDefaultTransmissionValues = {
    transmissionPrincipale: "TCP/IP ADSL/SDSL",
    transmissonSecondaire: "Aucun",
    niveauTransmission: "4",
    fournitureSim: false,
    leveeDouteAudio: false,
    leveeDouteVideo: false,
    interventionAlarme: false,
    GestionMoyenAcces: false,
}

export const secondFormDefaultSubscriptionPriceValues = {
    prixHT: 0,
    prixTTC: 0,
}