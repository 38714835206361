import { useState } from "react";
import './formulaire-consignes-surveillance.css';
import { TitleItemProps } from "../../../components/remote_surveillance_instructions_forms/TitleItem";
import NavBar from "../../../components/nav_bar/NavBar";
import SectionsTitles from "../../../components/remote_surveillance_instructions_forms/SectionsTitles";
import InstructionsFormContainer from "../../../components/remote_surveillance_instructions_forms/InstructionsFormContainer";

const FormulaireConsignesTelesurveillance = () => {
    const [tabIndex, setTabIndex] = useState<number>(1);
    const [showMenu, setShowMenu] = useState<boolean>(false);
    // const sectionTitles: Array<string> = ["Identification", "Coordonnées personnelles", "Consignes de sécurité", "Politique de confidentialité"];
    // Forms titles 
    const data: Array<TitleItemProps> = [
        { index: 1, title: 'Coordonnées et correspondants', isActive: tabIndex == 1, isDisabled: false },
        { index: 2, title: 'Supports de transmission et informations d\'intervention', isActive: tabIndex == 2, isDisabled: tabIndex < 2 },
        { index: 3, title: 'Horaires d\'ouverture', isActive: tabIndex == 3, isDisabled: tabIndex < 3 },
        { index: 4, title: 'Traitement d\'informations', isActive: tabIndex == 4, isDisabled: tabIndex < 4 },
    ];

    return (
        <div className="main-container">
            <NavBar setShowMenu={setShowMenu} showToggleButton={true}/>
            <div className="main-component-container">
                <SectionsTitles data={data} showMenu={showMenu} setShowMenu={setShowMenu}/>
                <InstructionsFormContainer data={data} tabIndex={tabIndex} setTabIndex={setTabIndex}/>
            </div>
        </div>
    );
}

export default FormulaireConsignesTelesurveillance;