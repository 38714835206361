import { CSSTransition } from 'react-transition-group'
import './nav-bar.css'
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { Button } from '@mui/material';

type NavBarPropsType = {
    setShowMenu: Function
    showToggleButton: boolean
}
function NavBar(props: NavBarPropsType) {
    return (
        <div className='nav-bar'>
            {
                props.showToggleButton &&
                (<Button onClick={() => { props.setShowMenu((prev: boolean) => !prev) }} className={`toggle-button`}>
                    <MenuIcon sx={{ mr: 1 }} color='primary' />
                </Button>)
            }
            <img src={process.env.PUBLIC_URL + '/logo.png'} className='logo' />
        </div>
    );
}

export default NavBar;