import { Checkbox, ClickAwayListener, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, TextField, TextareaAutosize, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import '../form-container.css'
import { ChangeEvent, useEffect, useState } from 'react';
import { interventionsContent, leveeDouteContent, supportContent, supportContent2 } from '../../../utils/HelpToolTipContents';
import { HelpToolTip } from '../../utils/HelpToolTips';
import { SelectComponent } from '../../utils/SelectComponent';
import { CustomRadioGroup } from '../../utils/RadioGroup';
import MoneyInput from "@rschpdr/react-money-input";
import { locale } from 'dayjs';

export type RadioButtonsType = {
    intrusionDetection: boolean,
    fireDetection: boolean,
    assaultAlert: boolean,
    technicalAlarms: boolean,
    MESSchedules: boolean,
    technicalSystemsDefaults: boolean,
}

export type SupportObjectType = {
    transmissionPrincipale: string,
    transmissonSecondaire: string,
    niveauTransmission: string,
    fournitureSim: boolean,
    leveeDouteAudio: boolean,
    leveeDouteVideo: boolean,
    interventionAlarme: boolean,
    GestionMoyenAcces: boolean,
}

export type subscriptionObjectType = {
    prixHT: number,
    prixTTC: number,
}

type SecondFormPropsTypes = {
    title: string,
    register: Function,
    checkBoxes: RadioButtonsType,
    setCheckBoxes: Function,
    supports: SupportObjectType,
    setSupports: Function,
    amount: subscriptionObjectType,
    setAmount: Function,
    trigger: Function,
    errors: any,
}

export const SecondFormContainer = ({ amount, setAmount, title, register, checkBoxes, setCheckBoxes, trigger, errors, supports, setSupports }: SecondFormPropsTypes) => {
    // States 
    const [openToolTip, setOpenToolTip] = useState(-1);

    // Handlers
    const handleShowToolClose = (val: number) => {
        if (openToolTip === val) {
            setOpenToolTip(-1);
        }
    };
    const handleShowToolOpen = (val: number) => {
        setOpenToolTip(val);
    };
    const handleSelectChange = (e: SelectChangeEvent, property: number) => {
        const changedSupports = supports;
        switch (property) {
            case 0:
                changedSupports.transmissionPrincipale = e.target.value;
                break;
            case 1:
                changedSupports.transmissonSecondaire = e.target.value;
                break;
        }
        setSupports({ ...changedSupports });
    };
    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>, property: number) => {
        const changedSupports = supports;
        switch (property) {
            case 0:
                trigger();
                changedSupports.niveauTransmission = e.target.value;
                break;
        }
        setSupports({ ...changedSupports });
    };
    function handlePrixHTChange(e: any) {
        setAmount({
            prixHT : e.target.value,
            prixTTC : parseFloat((e.target.value * 1.2).toFixed(2)) // calculalting total price (20%)
        });
    }
    function handlePrixTTCChange(e: any) {
        setAmount({
            prixHT : parseFloat((e.target.value/1.2).toFixed(2)), // calculalting total price without taxes
            prixTTC : e.target.value
        });
    }

    // useEffects 
    useEffect(() => {
        // scroll back to the top of the card
        const body = document.querySelector('#scrolled-card');
        body?.scrollIntoView({
            behavior: 'smooth'
        })
    }, [])

    return (
        <div className="form-card" id='scrolled-card'>

            {/* Header */}
            <div className="heading">
                {title}
            </div>

            <div className="sub-heading">
                Typologie des alertes
            </div>

            <Grid container spacing={2} mt={1} mb={5}>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.intrusionDetection}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, intrusionDetection: event.target.checked })
                                }} />
                        }
                        label="Détection d’intrusion" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.fireDetection}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, fireDetection: event.target.checked })
                                }} />
                        }
                        label="Détection incendie" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.assaultAlert}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, assaultAlert: event.target.checked })
                                }} />
                        }
                        label="Alerte agression" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.technicalAlarms}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, technicalAlarms: event.target.checked })
                                }} />
                        }
                        label="Alarmes techniques" />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.MESSchedules}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, MESSchedules: event.target.checked })
                                }} />
                        }
                        label="Horaires de mise en service" />
                </Grid>
                <Grid item xs={12} md={9}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBoxes.technicalSystemsDefaults}
                                onChange={(event) => {
                                    setCheckBoxes({ ...checkBoxes, technicalSystemsDefaults: event.target.checked })
                                }} />
                        }
                        label="Défauts techniques système (Défaut secteur, batterie basse, autoprotection, test cyclique…)" />
                </Grid>
            </Grid>

            <div className="sub-heading">
                Support de transmission
                {/* <ClickAwayListener onClickAway={() => handleShowToolClose(2)}>
                    <div>
                        <HelpToolTip
                            content={supportContent2}
                            handleShowToolClose={() => handleShowToolClose(2)}
                            handleShowToolOpen={() => handleShowToolOpen(2)}
                            openToolTip={openToolTip === 2}
                        />
                    </div>
                </ClickAwayListener> */}
            </div>
            <Grid
                container
                spacing={2} mt={0}
                mb={5}
            >
                <SelectComponent
                    data={["TCP/IP ADSL/SDSL", "GSM/GPRS/3G/4G"]}
                    title='Transmission principale'
                    value={supports.transmissionPrincipale}
                    onChange={handleSelectChange}
                    property={0}
                />

                <SelectComponent
                    data={["Aucun", "TCP/IP ADSL/SDSL", "GSM/GPRS/3G/4G"]}
                    title='Transmission secondaire'
                    value={supports.transmissonSecondaire}
                    onChange={handleSelectChange}
                    property={1}
                />
                <Grid item xs={12}>
                    <CustomRadioGroup
                        title='Niveau de transmission'
                        data={["1", "2", "3", "4", "non conforme au référentiel APSAD R31"]}
                        defaultValue={supports.niveauTransmission}
                        onChange={handleRadioChange}
                        property={0}
                    />
                </Grid>
                {(supports.transmissionPrincipale == "GSM/GPRS/3G/4G" ||
                    supports.transmissonSecondaire == "GSM/GPRS/3G/4G") &&
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={supports.fournitureSim}
                                    onChange={(event) => {
                                        setSupports({ ...supports, fournitureSim: event.target.checked })
                                    }} />
                            }
                            label="Fourniture de la carte SIM" />
                    </Grid>
                }
            </Grid>

            <div className="sub-heading">
                Levée de doute/intervention sur alarme
                {/* <ClickAwayListener onClickAway={() => handleShowToolClose(3)}>
                    <div>
                        <HelpToolTip
                            content={leveeDouteContent}
                            handleShowToolClose={() => handleShowToolClose(3)}
                            handleShowToolOpen={() => handleShowToolOpen(3)}
                            openToolTip={openToolTip === 3}
                        />
                    </div>
                </ClickAwayListener> */}
            </div>
            <Grid container spacing={2} mt={0} mb={5}>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={supports.leveeDouteAudio}
                                onChange={(event) => {
                                    setSupports({ ...supports, leveeDouteAudio: event.target.checked })
                                }} />
                        }
                        label="Levée de doute audio" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={supports.leveeDouteVideo}
                                onChange={(event) => {
                                    setSupports({ ...supports, leveeDouteVideo: event.target.checked })
                                }} />
                        }
                        label="Levée de doute vidéo" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={supports.interventionAlarme}
                                onChange={(event) => {
                                    setSupports({ ...supports, interventionAlarme: event.target.checked })
                                }} />
                        }
                        label="Intervention sur alarme" />
                </Grid>
                {supports.interventionAlarme &&
                    <Grid item xs={12} md={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={supports.GestionMoyenAcces}
                                    onChange={(event) => {
                                        setSupports({ ...supports, GestionMoyenAcces: event.target.checked })
                                    }} />
                            }
                            label="Gestion des moyens d’accès" />
                    </Grid>
                }
            </Grid>


            <div className="sub-heading">
                Abonnement
                {/* <ClickAwayListener onClickAway={() => handleShowToolClose(3)}>
                    <div>
                        <HelpToolTip
                            content={leveeDouteContent}
                            handleShowToolClose={() => handleShowToolClose(3)}
                            handleShowToolOpen={() => handleShowToolOpen(3)}
                            openToolTip={openToolTip === 3}
                        />
                    </div>
                </ClickAwayListener> */}
            </div>
            <Grid container spacing={2} mt={0}>
                <Grid item xs={12} sm={6}>
                    <MoneyInput
                        customInput={TextField}
                        style={{ width: "100%" }}
                        currencyConfig={{
                            locale: "fr-FR",
                            currencyCode: "EUR"
                        }}
                        variant="outlined"
                        label="Prix HT"
                        onChange={handlePrixHTChange}
                        value={amount.prixHT}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MoneyInput
                        customInput={TextField}
                        style={{ width: "100%" }}
                        currencyConfig={{
                            locale: "fr-FR",
                            currencyCode: "EUR"
                        }}
                        variant="outlined"
                        label="Prix TTC"
                        onChange={handlePrixTTCChange}
                        value={amount.prixTTC}
                        // disabled={true}
                    />
                </Grid>
            </Grid>
        </div >
    );
}

