import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"

type CheckBoxPropType = {
    title: string,
    data: Array<string>,
    value: string,
    onChange: Function,
    property: number
}

export function SelectComponent({ title, data, value, onChange, property }: CheckBoxPropType) {
    return (
        <Grid item xs={12} md={6}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    value={value}
                    label={title}
                    onChange={(e) => onChange(e, property)}
                >
                    {data.map((d, i)=>( <MenuItem key={`${d}-${i}`} value={d}>{d}</MenuItem>))}
                </Select>
            </FormControl>
        </Grid>
    )
}