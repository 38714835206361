import "./history-container.css"
import { TitleItemProps } from "../remote_surveillance_instructions_forms/TitleItem";
import { Autocomplete, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EnhancedTable from "../tables/HistoryTable";
import HistoryTable from "../tables/HistoryTable";
import { useEffect, useState } from "react";
import axios from "axios";

type HistoryContainerProps = {

}

export interface HistoryData {
    id: number;
    name: string;
    date: Date;
    status: string;
}

function HistoryContainer(props: HistoryContainerProps) {
    const [historyData, setHistoryData] = useState<Array<HistoryData>>([]);
    const [filteredHistoryData, setFilteredHistoryData] = useState<Array<HistoryData>>([]);
    const [selectedStatus, setSelectedStatus] = useState<Array<String>>([]);
    const status = [
        { title: "En cours" },
        { title: "A valider" },
        { title: "Validé" },
        { title: "Annulé" }
    ];
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const onTagsChange = (event: any, values: any) => {
        // console.log(values);
        setSelectedStatus(values.map((v: any) => v.title));
    }

    const getStatus = (status: string) => {
        switch (status) {
            case "ARCHIVED":
                return "Validé";

            case "CLOSED":
                return "Validé";

            case "OPEN":
                return "En cours"

            case "SIGNED":
                return "A valider"

            case "ABANDONED":
                return "Annulé"
        }
    }

    useEffect(() => {
        // get informations from server
        axios.get(`${process.env.REACT_APP_SERVER_URL}oodrive/get`)
            .then((data) => {
                var unfilteredData = data.data.map((d: any) => {
                    var date = new Date(d.date);
                    // var date = new Date(d.date).toLocaleDateString("fr-FR");
                    // var time = new Date(d.date).toLocaleTimeString("fr-FR");
                    var status = getStatus(d.status);

                    return {
                        "id": d.contract_id,
                        "name": d.name,
                        "date": date,
                        "status": status
                    }
                });
                setHistoryData(unfilteredData);
                setFilteredHistoryData(unfilteredData);
            })
            .catch((error) => {
                if (error.response) {

                } else {

                }
            });
    }, []);

    useEffect(() => {
        if (selectedStatus.length > 0)
            setFilteredHistoryData(historyData.filter((item: HistoryData) => selectedStatus.includes(item.status)));
        else
            setFilteredHistoryData(historyData);
    }, [selectedStatus]);

    return (
        <div className="history-form-container">
            <div className="history-form-card">
                {/* Header */}
                <div className="history-heading history-space-between">
                    Historique des envois
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="checkboxes-tags-demo"
                        options={status}
                        disableCloseOnSelect
                        isOptionEqualToValue={(option, value) => option.title === value.title}
                        getOptionLabel={(option) => option.title}
                        onChange={onTagsChange}
                        renderOption={(props, option, { selected }) => {
                            const { ...optionProps } = props;
                            return (
                                <li {...optionProps}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.title}
                                </li>
                            );
                        }}
                        style={{ width: 350 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Statut" placeholder="Favorites" />
                        )}
                    // onSelect={}
                    />
                </div>

                <HistoryTable
                    rows={filteredHistoryData}
                />
            </div>
        </div>
    );
}

export default HistoryContainer;