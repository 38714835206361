import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress, IconButton } from '@mui/material';

type FormImportDialogProps = {
    open: boolean,
    isLoading: boolean,
    importDataErrorStatus: number,
    handleClickOpen: any,
    handleClose: any,
    handleSubmit: any,
}

export default function FormImportDialog({ open, handleClickOpen, handleClose, handleSubmit, importDataErrorStatus, isLoading }: FormImportDialogProps) {
    return (
        <React.Fragment>
            {/* <IconButton aria-label="importer des données" onClick={handleClickOpen}>
                <DownloadForOfflineTwoToneIcon />
            </IconButton> */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}
            >
                <DialogTitle>
                    Importation de données
                    {isLoading && <CircularProgress disableShrink size=".8rem" sx={{ ml: 2 }} />}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Veuillez entrer l'identifiant de référence du formulaire que vous souhaitez importer.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="ref"
                        name="ref"
                        label="identifiant de référence"
                        fullWidth
                        variant="outlined"
                        error={importDataErrorStatus != 0}
                        helperText={importDataErrorStatus == 1 ?
                            "Aucune correspondance n'a été trouvée." : importDataErrorStatus == 2 ?
                                "Une erreur est survenue lors de la récupération des données. Merci de bien vouloir contacter le service technique." : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button type="submit">Importer</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}