import React, { useEffect, useRef, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import { Height, Visibility, VisibilityOff } from "@mui/icons-material";
import { TableData, createData } from "../../../tables/ContactTable";
import { addContactValidationSchema } from "../../../../utils/YupConfigurations";

type contactModalType = {
    open: boolean,
    isModify: boolean, // true lorsqu'on ouvre la modal en mode modification et non ajout
    index: number,
    selectedContact: TableData
}

type AddContactModalProps = {
    contactModalStatus: contactModalType,
    handleOpenContactModal: Function,
    handleCloseContactModal: Function,
    handleContactAdd: Function,
    handleContactModify: Function
}

export default function AddContactModal(props: AddContactModalProps) {
    // states 
    const [showPassword, setShowPassword] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(false);

    // handlers
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    // UseEffect
    useEffect(() => {
        setIsChecked(props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact.c : isChecked);
    }, [props.contactModalStatus.index])

    // UseEffect
    useEffect(() => {
        reset();
    }, [props.contactModalStatus.selectedContact])

    const modalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            lg: "50%",
            xs: "80%"
        },
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        fontFamily: `'Barlow', sans-serif`,
    };

    const {
        register,
        reset,
        formState: { errors, isDirty, isValid },
        getValues
    } = useForm({
        mode: 'all',
        resolver: yupResolver(addContactValidationSchema)
    });

    return (
            <Modal
                open={props.contactModalStatus.open}
                onClose={() => { props.handleCloseContactModal(); reset(); setIsChecked(false); }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="scrollable-conatainer"
            >
                <Box sx={modalStyle}>
                    {/* En-tete du modal */}
                    <div className='modal-header'>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ marginBlockEnd: 3 }}>
                            Ajout de correspondants
                        </Typography>

                        <button className={`close-button`} onClick={() => { props.handleCloseContactModal() }}><CloseRoundedIcon /></button>
                    </div>

                    {/* Formulaire d'ajout */}
                    <Grid container spacing={2} mt={1} mb={5}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Nom*"
                                id="nom"
                                variant="outlined"
                                margin="dense"
                                // size='small'
                                defaultValue={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.name : ""}
                                // value={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.name : ""}
                                {...register('name')}
                                error={errors.name ? true : false}
                                helperText={errors.name?.message} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Prénom*"
                                id="prenom"
                                variant="outlined"
                                margin="dense"
                                // size='small'
                                defaultValue={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.firstName : ""}
                                // value={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.firstName : ""}
                                {...register('firstName')}
                                error={errors.firstName ? true : false}
                                helperText={errors.firstName?.message} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="N° de Téléphone*"
                                id="tel"
                                variant="outlined"
                                margin="dense"
                                // size='small'
                                defaultValue={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.tel : ""}
                                // value={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.tel : ""}
                                {...register('phoneNumber')}
                                error={errors.phoneNumber ? true : false}
                                helperText={errors.phoneNumber?.message} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="E-mail"
                                id="email"
                                variant="outlined"
                                margin="dense"
                                // size='small'
                                defaultValue={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.email : ""}
                                // value={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.email : ""}
                                {...register('email')}
                                error={errors.email ? true : false}
                                helperText={errors.email?.message} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Mot de passe*"
                                id="pwd"
                                variant="outlined"
                                margin="dense"
                                // size='small'
                                defaultValue={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.pwd : ""}
                                // value={props.contactModalStatus.isModify ? props.contactModalStatus.selectedContact?.pwd : ""}
                                fullWidth
                                {...register('password')}
                                error={errors.password ? true : false}
                                helperText={errors.password?.message}
                                type={showPassword ? "text" : "password"}

                                // Button permettant de Cacher/afficher le text
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Afficher le mot de passe."
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isChecked}
                                onChange={handleCheckedChange}
                            />
                        }
                        label="Autorisé à modifier les consignes" />

                    {/* Bouton de validation */}
                    <div className="modal-button-container">
                        <Button
                            className={`forward-button`}
                            variant="contained"
                            disabled={(!isDirty || !isValid) && !props.contactModalStatus.isModify}
                            onClick={() => {
                                if (!props.contactModalStatus.isModify) {
                                    props.handleContactAdd(createData(
                                        getValues("name"),
                                        getValues("firstName"),
                                        getValues("phoneNumber"),
                                        getValues("email")!,
                                        getValues("password"),
                                        isChecked,
                                    ));
                                    // vider les champs 
                                    reset();
                                    setIsChecked(false);
                                } else {
                                    props.handleContactModify(createData(
                                        getValues("name"),
                                        getValues("firstName"),
                                        getValues("phoneNumber"),
                                        getValues("email")!,
                                        getValues("password"),
                                        isChecked,
                                    ), props.contactModalStatus.index)
                                    reset();
                                    setIsChecked(false);
                                }
                            }}>
                            {props.contactModalStatus.isModify ? "Modifier" : "Ajouter"}
                        </Button>
                    </div>
                </Box>
            </Modal >
    );
}