import { Button, createTheme } from '@mui/material';
import './navigation-buttons.css'

type NavigationButtonsPropsTypes = {
    selectedTabIndex: number,
    totalNumberOfTabs: number,
    setTabIndex: Function,
    fowardButtonIsActif: Function,
    trigger: any,
    handleValidate: Function
}

function NavigationButtons(props: NavigationButtonsPropsTypes) {

    const handleNextButtonClick = () => {
        
        if (props.selectedTabIndex < props.totalNumberOfTabs) {
            if (props.fowardButtonIsActif()) {
                props.setTabIndex(props.selectedTabIndex + 1);
            } else {
                props.trigger();
            }
        }
    }

    const handlePrevButtonClick = () => {
        if (props.selectedTabIndex > 1) {
            props.setTabIndex(props.selectedTabIndex - 1);
        }
    }

    return (
        <div className="navigation-button-main-container">
            <div className='navigation-prev-button-container'>
                {props.selectedTabIndex > 1 &&
                    <button
                        onClick={handlePrevButtonClick}
                        // color='secondary'
                        className={`back-button`}>
                        Retour
                    </button>}
            </div>
            <div className='navigation-foward-button-container'>
                {props.selectedTabIndex < props.totalNumberOfTabs &&
                    <button
                        // disabled={!props.fowardButtonIsActif()}
                        className={`forward-button`}
                        color='primary'
                        // variant="contained"
                        onClick={handleNextButtonClick}
                    >
                        Suivant
                    </button>}

                {props.selectedTabIndex == props.totalNumberOfTabs &&
                    <button
                        // disabled={!props.fowardButtonIsActif()}
                        className={`forward-button`}
                        color='primary'
                        // variant="contained"
                        onClick={() => { props.handleValidate() }}
                    >
                        Valider
                    </button>}
            </div>
        </div>
    );
}

export default NavigationButtons;